@use '../global' as *;
/*===========================
/staff/
=========================== */

.staff-introduction {
  &__character {
    display: flex;
  }
  &__img {
    width: 120px;
    flex-basis: 120px;
    flex-shrink: 0;
    flex-grow: 0;
  }
  &__block {
    margin-left: 16px;
  }
  &__appeal {
    font-weight: 600;
  }
  &__belong {
    margin-top: 12px;
    color: #999;
    @include fz(10);
  }
  &__staff-name {
    font-weight: bold;
  }
  &__contents {
    margin-top: 16px;
  }
}
