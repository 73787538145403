@use '../global' as *;

/*===========================
/results/
=========================== */
.results-page {
  margin-top: 24px;
}
.form-txt {
  @include fz(14);
  line-height: 1.75;
  margin-bottom: 20px;

  @include mq(md) {
    @include fz(16);
    margin-bottom: 50px;
  }
}

.table-form {
  &__select {
    width: 100%;
    position: relative;
    display: inline-block;
    @include mq(lg) {
      max-width: 300px;
    }
    &::before {
      position: absolute;
      top: 1rem;
      right: 1rem;
      content: '';
      display: inline-block;
      width: 10px;
      height: 10px;
      border-top: solid 2px #222;
      border-right: solid 2px #222;
      transform: rotate(135deg);
    }
  }
  ::placeholder {
    color: #ccc;
  }

  width: 100%;
  background-color: #f2f5ff;

  @include mq(md) {
    background-color: #fff;
  }

  th,
  td {
    display: block;
    padding: 0 12px;

    @include mq(md) {
      display: table-cell;
      border: 1px solid #ccc;
      padding: 16px 12px;
      @include fz(16);
    }
  }

  th {
    padding-top: 12px;
    text-align: left;
    @include fz(14);
    margin-bottom: 10px;

    @include mq(md) {
      background-color: #f2f5ff;
      width: 280px;
    }
  }

  td {
    padding-bottom: 12px;

    a {
      color: #3b7de9;
      margin-right: 10px;
    }
  }

  tr {
    border: 1px solid #ccc;
  }

  border-collapse: collapse;

  select {
    @include fz(16);
    border: 1px solid #ccc;
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
    padding: 9px 18px;

    @include mq(md) {
      max-width: 300px;
    }
  }

  [type='search'] {
    @include mq(md) {
      max-width: 300px;
    }

    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 4px;
    width: 100%;
    padding: 9px 18px 9px 45px;
  }
}

.input-search-wrap {
  .btn {
    margin-top: 24px;
    @include mq(md) {
      padding: 8px;
      margin: 0 0 0 24px;
      width: 180px;
    }
  }
  @include mq(md) {
    display: flex;
  }
}

.input-searchbox {
  position: relative;

  .material-icons-outlined {
    position: absolute;
    left: 16px;
    top: 50%;
    color: #ccc;
    transform: translateY(-50%);
  }
}

.search-result {
  background-color: #f0f1f3;
  padding: 24px;

  > div {
    background-color: #fff;
    border: 1px solid #cccccc;
    padding: 16px;

    @include mq(md) {
      padding: 24px;
    }

    @include fz(12);
    display: flex;

    @include mq(md) {
      @include fz(14);
    }
  }
}

.search-result-img-wrapper {
  display: block;
  width: 72px;
  height: 72px;
  margin-right: 8px;

  @include mq(md) {
    width: 120px;
    height: 90px;
    margin-right: 16px;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
}

.search-result__meta {
  flex: 1;

  small {
    font-size: inherit;
    display: block;
    margin-top: 5px;

    @include mq(md) {
      @include fz(12);
    }

    &::before {
      content: '※';
    }
  }
}

.search-result-heading {
  font-weight: bold;
  @include fz(14);

  @include mq(md) {
    @include fz(20);
  }
}

.search-result-data {
  @include mq(md) {
    max-width: 258px;
  }

  > div {
    margin: 4px 0;
  }

  dt {
    font-weight: bold;
  }

  dd {
    width: 40%;

    @include mq(md) {
      width: 30%;
    }
  }

  > div {
    display: flex;
    justify-content: space-between;
  }
}
