@use '../global' as *;


.textset {
  margin-bottom: 24px;

  @include mq(md) {
    margin-bottom: 16px;
  }
}

.textset_heading {
  font-weight: bold;
  margin-bottom: 10px;

  @include mq(md) {
    @include fz(20);
  }
}

.textset_main {
  line-height: 1.75;
  @include fz(12);
  margin-bottom: 20px;

  @include mq(md) {
    @include fz(16);
  }
}

@include mq(md) {
  .textset_inner {
    display: flex;
  }

  .textset_before {
    flex: 1;
  }

  .textset_after {
    width: 49.2%;
    margin-left: 24px;

    img {
      width: 100%;
    }
  }

  .textset_img {
    width: 401px;
    margin-bottom: 30px;

  }
}
