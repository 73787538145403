@use '../global'as *;

.smalltxt01 {
  display: block;
  margin-bottom: 20px;

  &::before {
    content: '※';
    @include fz(14);
  }
}
