@use '../global' as *;

.heading-lv3 {
  @include fz(14);
  margin: 40px auto 24px;
  padding: 11px 24px;
  background-color: #f2f2f2;
  border-bottom: 1px solid #ccc;

  &.-fit {
    margin-right: 0;
    margin-left: 0;
  }

  @include mq(md) {
    @include fz(18);
    padding: 10px 24px;
    margin: 50px 0px 24px;
  }
}
