@use '../global' as *;

.bannerlist {
  margin-bottom: 100px;

  &.-shadow {
    img {
      box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
    }
  }
  &.-clum3{
    @include mq(md) {
      li{
        width: 32%;
      }
    }
  }

  img {
    width: 100%;
  }

  li {
    margin-bottom: 16px;

    @include mq(md) {
      width: 48.8%;
      margin-bottom: 20px;
    }
  }

  @include mq(md) {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 75px;
    justify-content: space-between;
  }
}
