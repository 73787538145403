@use '../global' as *;
/*===========================
/column/
=========================== */
.column-page {
  background: #fbfbfb;
  @include mq(lg) {
    background: $c-color-base;
  }
  &__inner {
    padding: 24px 15px 54px;
    @include mq(lg) {
      padding: 40px 0 120px;
      @include content-wrap();
    }
  }
  &__contents {
    margin: 24px 0;
  }
}

//コラム詳細
.column-detail-page {
  @include mq(lg) {
  }
  &__inner {
    @include content-wrap();
    @include mq(lg) {
      width: 620px;
    }
  }
  &__report-link {
    margin-top: 24px;
    margin-bottom: 24px;
    @include mq(lg) {
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }
  &__article {
    h2 {
      margin-top: 25px;
      padding: 15px 15px;
      border-left: 4px solid $c-color-secondary;
      background-color: $c-color-primary;
      color: #fff;
      font-weight: bold;
      @include fz(20);
      @include mq(lg) {
        margin-top: 40px;
        padding: 12px 15px;
        @include fz(24);
      }
    }
    h3 {
      margin-top: 40px;
      color: #0060af;
      @include fz(18);
      @include mq(lg) {
        @include fz(22);
        line-height: 1.2;
      }
    }

    h4 {
      margin-top: 24px;
      padding-bottom: 2px;
      border-bottom: 2px solid #ffc631;
      @include fz(16);
      @include mq(lg) {
        margin-top: 30px;
      }
    }

    h5 {
      margin-top: 24px;
      padding-bottom: 2px;
      border-bottom: 2px solid #ffc631;
      @include fz(16);
      @include mq(lg) {
        margin-top: 30px;
      }
    }

    p {
      margin-top: 15px;
      @include mq(lg) {
        margin-top: 20px;
      }
    }

    ul,
    ol {
      margin-top: 20px;
      padding-left: 1em;
      margin-left: 1em;
    }

    ul li {
      list-style-type: disc;
      line-height: 1.4em;
      margin-bottom: 5px;
    }

    ol li {
      list-style-type: decimal;
      line-height: 1.4em;
      margin-bottom: 5px;
    }
  }
  &__bnr {
    @include content-wrap();
    margin-top: 40px;
    margin-bottom: 40px;
    @include mq(lg) {
      margin: 95px auto;
    }
  }
  &__columnist {
    margin-top: 80px;
    @include mq(lg) {
      margin-top: 40px;
    }
  }
}

.column-catch {
  &__img {
    width: 620px;
  }
  &__title {
    margin-top: 24px;
    @include fz(24);
  }
}

.column-catch {
  &__img {
    display: block;
    margin: auto;
  }
  &__date {
    margin-top: 24px;
    color: #999;
    font-weight: bold;
    @include fz(12);
  }
  &__title {
    margin: 8px 0 24px;
    font-weight: 600;
    @include fz(20);
  }
}

.column-anchor-box {
  padding: 24px 0;
  border-top: 1px solid $c-color-border;
  border-bottom: 1px solid $c-color-border;
  &__item {
    display: flex;
    align-items: center;
    &::before {
      content: '';
      display: inline-block;
      width: 6px;
      height: 6px;
      flex-basis: 6px;
      flex-grow: 0;
      flex-shrink: 0;
      background: $c-color-primary;
      border-radius: 50%;
    }
    &:not(:first-child) {
      margin-top: 8px;
    }
  }
  &__link {
    margin-left: 8px;
    color: $c-color-primary;
  }
}

.column-box01 {
  margin-top: 60px;
  &__img {
    display: block;
    margin: auto;
  }
  &__title {
    @include fz(16);
    font-weight: 600;
    @include mq(lg) {
      @include fz(12);
    }
  }
  &__text-block {
    margin-top: 24px;
    line-height: 1.75;
    @include mq(lg) {
      @include fz(16);
    }
  }
  &__paragraph {
    margin-top: 24px;
  }
}

.column-box02 {
  padding: 24px 0;
  &__img {
    display: block;
    margin: auto;
  }
  &__title {
    @include fz(16);
    font-weight: 600;
    @include mq(lg) {
      @include fz(20);
    }
  }
  &__img {
    margin-top: 24px;
    @include mq(lg) {
      margin: 0 16px 0 0;
      width: 300px;
      height: 200px;
      flex-basis: 300px;
      flex-grow: 0;
      flex-shrink: 0;
      display: initial;
      &.-reverse {
        margin: 0 0 0 16px;
      }
    }
  }
  &__text-block {
    margin-top: 24px;
    line-height: 1.75;
    @include mq(lg) {
      margin-top: 0;
      @include fz(16);
    }
  }
  &__paragraph {
    margin-top: 24px;
    @include mq(lg) {
      @include fz(16);
      margin-top: 24px;
    }
  }
}

// コラムを書いた人
.c-columnist {
  &__inner {
    padding: 16px 0;
    border-top: 1px solid $c-color-border;
    border-bottom: 1px solid $c-color-border;
    display: grid;
    grid-template-columns: 80px 1fr;
    gap: 8px;
    @include mq(lg) {
      grid-template-rows: 57px 1fr;
    }
  }
  &__imgWrap {
    font-weight: bold;
  }
  &__img {
    display: block;
  }
  &__text {
    grid-column: 1 / span 3;
    line-height: 1.75;
    @include mq(lg) {
      margin-left: 16px;
      grid-column: 2 / span 3;
    }
  }
  &__personWrap {
    @include mq(lg) {
      margin-left: 16px;
    }
  }
  &__person {
    @include fz(12);
    color: $c-color-label;
    @include mq(lg) {
      @include fz(14);
    }
  }
  &__person-name {
    @include fz(16);
    font-weight: bold;
    @include mq(lg) {
      @include fz(20);
    }
  }
  &__linkWrap {
    margin-top: 16px;
    text-align: center;
  }
  &__link {
    display: block;
    color: $c-color-link;
    @include fz(13);
  }
}
