@use '../global' as *;
/*===========================
/qa/
=========================== */
//　よくあるご質問
.faq-page {
  margin-top: 24px;
  @include mq(lg) {
    margin: 40px 0 160px;
  }
  &__inner {
    @include content-wrap();
    @include mq(lg) {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
    }
  }
  &__category {
    margin: 54px 0;
    @include mq(lg) {
      margin: 0;
      width: 280px;
      flex-basis: 280px;
      flex-grow: 0;
      flex-shrink: 0;
    }
  }
  &__faq {
    @include mq(lg) {
      width: 600px;
    }
  }
}

//よくあるご質問
.faq {
  &__list {
    margin-top: 24px;
    @include mq(lg) {
      margin-top: 0;
    }
  }
  &__item {
    &:last-of-type {
      border-bottom: 1px solid $c-color-border;
    }
  }
  &__question {
    padding: 16px 5px 12px;
    border-top: 1px solid $c-color-border;
    cursor: pointer;
    font-weight: bold;
    display: flex;
    align-items: center;
    @include fz(14);
    @include mq(lg) {
      adding: 20px 5px 19px;
    }
  }
  &__question-text {
    flex: 1;
  }
  &__toggle {
    position: relative;
    display: block;
    margin-left: auto;
    width: 14px;
    height: 2px;
    background: $c-color-primary;
    &::before {
      position: absolute;
      content: '';
      display: block;
      width: 2px;
      height: 14px;
      background: $c-color-primary;
      top: -6px;
      left: 6px;
    }
    &.is-active {
      &::before {
        width: 0;
      }
    }
  }
  &__icon {
    position: relative;
    margin-right: 8px;
    width: 24px;
    height: 24px;
    &::before {
      content: '';
      border: 1px solid $c-color-primary;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      display: block;
      background: $c-color-base;
    }
    &::after {
      position: absolute;
      content: 'Q';
      @include fz(11);
      font-weight: normal;
      color: $c-color-primary;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &.-answer {
      &::before {
        content: '';
        border: 1px solid $c-color-primary;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        display: block;
        background: $c-color-primary;
      }
      &::after {
        position: absolute;
        content: 'A';
        color: $c-color-base;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  &__answer {
    display: flex;
    padding: 2px 5px 16px;
    line-height: 1.75;
    &.isis-active {
      display: block;
    }
    @include mq(lg) {
      padding: 2px 5px 16px;
    }
  }
  &__answer-text {
    width: 85%;
    @include fz(14);
  }
}

//よくあるご質問
.c-opinion-list {
  &__list {
    margin-top: 24px;
  }
  &__item {
    border-top: 1px solid $c-color-border;
    &:last-of-type {
      border-bottom: 1px solid $c-color-border;
    }
  }
  &__title {
    padding: 16px;
    display: flex;
    font-weight: bold;
  }
  &__img {
    flex-basis: 72px;
    flex-shrink: 0;
    margin-right: 8px;
    display: block;
  }
  &__answer {
    display: flex;
    padding: 2px 5px 16px;
    line-height: 1.75;
    &.isis-active {
      display: block;
    }
  }
  &__answer-text {
    width: 85%;
  }
  &__person {
    color: $c-color-text-gray;
  }
  &__date {
    @include fz(12);
    display: block;
  }
  &__person-name {
    @include fz(10);
  }
  &__ellipsis {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 1.75;
  }
}

.search-btn {
  display: flex;
  align-items: center;
  position: relative;
  padding: 12px 15px;
  background: #fff;
  color: $c-color-primary;
  border-radius: 6px;
  border: 2px solid $c-color-secondary;
  width: 100%;
  text-align: left;
  font-weight: 600;
  transition: $c-transition;
  &:hover {
    background: #fff9ea;
  }
  &::before {
    position: absolute;
    right: 20px;
    top: 15px;
    @include arrow(10, 2, $c-color-secondary, bottom);
  }
  &:not(:first-child) {
    margin-top: 24px;
  }
  @include mq(lg) {
    @include fz(14);
    height: 48px;
    &:not(:first-child) {
      margin-top: 16px;
    }
  }
  &.-anchor {
    &::before {
      position: absolute;
      right: 20px;
      top: 15px;
      @include arrow(10, 2, $c-color-primary, bottom);
    }
  }
  &.-main-border {
    position: relative;
    border: 1px solid $c-color-primary;
    &::before {
      position: absolute;
      right: 20px;
      top: 20px;
      @include arrow(7, 2, $c-color-primary, right);
    }
    &.-active {
      background: $c-color-light;
      color: $c-color-primary;
      &::before {
        @include arrow(7, 2, $c-color-primary, right);
      }
    }
    &:hover {
      transition: $c-transition;
      background: $c-color-light;
    }
  }
  &__icon {
    margin-right: 8px;
    vertical-align: middle;
    flex-basis: 24px;
    width: 24px;
    flex-grow: 0;
    flex-shrink: 0;
  }
  &__text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 80%;
  }
}
