@use '../global' as *;

.swiper-all-wrapper {
  overflow-x: hidden;
}
// スタッフhover
.js-top-swiper.swiper {
  overflow: initial;
  // @include mq(lg) {
  //   overflow: initial;
  // }
}

.staff-hover {
  cursor: pointer;
  transition: all 0.3s;
  position: relative;
  &:hover {
    transform: scale(1.2, 1.1);
    z-index: 9;
    box-shadow: 0px 5px 8px 0px rgb(48 64 77 / 19%);
    & .staff-hover__wrap img + img {
      opacity: 1;
    }
    & .staff-hover__text {
      opacity: 1;
    }
  }
  &__wrap {
    & img + img {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  &__text {
    width: 100%;
    box-sizing: border-box;
    opacity: 0;
    padding: 15px;
    position: absolute;
    bottom: 0;
    background: rgba(255, 255, 255, 0.7);
    text-align: center;
    font-weight: bold;
    color: #384458;
    line-height: 20px;
    transition: all 0.3s;
    z-index: 2;
    @include mq(lg) {
      padding: 30px 20px;
      line-height: 24px;
    }
    > p {
      @include fz(10);
      @include mq(lg) {
        @include fz(14);
      }
    }
  }
  &__name {
    @include fz(16);
    @include mq(lg) {
      @include fz(18);
    }
  }
}

.staff-slide-arrow {
  position: absolute;
  top: 50%;
  transform: translateX(-50%);
  z-index: 1;
  width: 40px;
  height: 80px;
  transform: translateY(-50%);
  &--next {
    right: 1rem;
    background-image: url(/_files/_keeper/images/next_btn.png);
  }
  &--prev {
    left: 1rem;
    background-image: url(/_files/_keeper/images/prev_btn.png);
  }
}

.swiper-button-disabled {
  display: none;
}
