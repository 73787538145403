@use '../global' as *;

.contents-wrapper {
  width: 100%;
  &.-mt02 {
    margin-top: 24px;
    @include mq(lg) {
      margin-top: 34px;
    }
  }
  // @include mq(lg) {
  //   margin-top: 88px;
  // }
}

.content-wrapper-inner {
  @include content-wrap();
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;

  &.-pdtb {
    padding-top: 50px;
    padding-bottom: 54px;
    line-height: 1.75;
  }

  &.-menu {
    padding-top: 24px;
    padding-bottom: 8px;

    @include mq(md) {
      padding-top: 36px;
      padding-bottom: 20px;
    }
  }

  @include mq(md) {
    &.-pdtb {
      padding-top: 96px;
      padding-bottom: 96px;
    }
  }
}

.content-wrapper {
  padding: 0 15px;

  &.-staff {
    padding: 0;
  }

  &.-sidemenu {
    margin-bottom: 60px;

    @include mq(md) {
      margin-bottom: 80px;
    }
  }
}

.section-header__txt {
  text-align: center;
  @include fz(18);
  font-weight: bold;
  margin-bottom: 12px;

  @include mq(md) {
    &.-l {
      @include fz(24);
    }

    @include fz(20);
    margin-bottom: 6px;
  }
}

.section-header__img {
  img {
    display: block;
    margin: 0 auto 20px;
  }

  @include mq(md) {
    img {
      margin-bottom: 10px;
    }
  }
}
