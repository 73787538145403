@use '../global' as *;
.c-column-item {
  border-top: 1px solid $c-color-border;
  display: block;
  padding: 16px;
  background: $c-color-base;
  &:last-of-type {
    border-bottom: 1px solid $c-color-border;
  }
  &__title-img {
    float: left;
    margin-right: 12px;
    width: 120px;
    height: 72px;
    &.-staff {
      width: 90px;
    }
    &.-shop {
      width: 120px;
    }
  }
  &__title-text-wrap {
    @include clearfix;
    overflow: hidden;
    min-height: 70px;
  }
  &__title-text {
    font-weight: bold;
    @include fz(14);
    line-height: 1.5;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    @include mq(lg) {
      @include fz(20);
    }
  }
  &__contents {
    margin-top: 12px;
  }
  &__label {
    display: flex;
    flex-wrap: wrap;
    .category-label {
      &:not(:first-of-type) {
        margin-left: 8px;
      }
      &:nth-child(3n + 1) {
        margin-left: 0;
      }
      &:nth-child(n + 4) {
        margin-top: 8px;
      }
    }
  }
  &__body {
    overflow: hidden;
    margin-top: 10px;
  }
  &__body-text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  &__item-name {
    margin-top: 8px;
    @include fz(12);
    color: $c-color-text-gray;
  }
  &__belong {
    @include fz(10);
  }
  &__price {
    margin-top: 6px;
  }
  &__staff-name {
    font-weight: bold;
    @include fz(12);
  }
  &__date {
    margin-bottom: 5px;
    font-weight: normal;
    @include fz(12);
    color: $c-color-text-gray;
  }
}
