@use '../global' as *;
//トップMV
.top-mv {
  position: relative;
  &__inner {
    @include content-wrap();
  }
  &__text {
    position: absolute;
    bottom: 40px;
    @include mq(lg) {
      top: 50%;
    }
  }
  &__text-main {
    padding: 2px 8px;
    display: inline-block;
    @include fz(16);
    font-weight: bold;
    background: $c-color-primary;
    color: $c-color-base;
    &:not(:first-of-type) {
      margin-top: 8px;
    }
    @include mq(lg) {
      padding: 8px 16px;
      @include fz(30);
    }
  }
  &__text-sub {
    margin-top: 12px;
    padding: 2px 8px;
    display: inline-block;
    @include fz(10);
    background: $c-color-base;
    color: $c-color-primaryDark;
    @include mq(lg) {
      padding: 8px 16px;
      @include fz(16);
    }
  }
  &__img {
    width: 100%;
  }
}

.mv-cta {
  padding: 9px 0.5rem 8px;
  width: 100%;
  background: $c-color-base;
  z-index: 90;
  @include mq(lg) {
    padding: 19px 0 18px;
  }
  &.-stop {
    position: relative;
  }
  &__inner {
    display: flex;
    justify-content: center;
  }
  &__item {
    width: 100%;
    @include mq(lg) {
      width: 400px;
    }
    &:last-of-type {
      margin-left: 0.5rem;
      @include mq(lg) {
        margin-left: 20px;
      }
    }
  }
  & .btn {
    @include mq(lg) {
      padding: 13px 0;
      @include fz(18);
      .material-icons {
        margin-right: 8px;
        @include fz(20);
      }
      & > span {
        @include fz(18);
      }
    }
  }
  &__icon {
    display: block;
    margin: 0 auto 8px;
  }
  &__link {
    display: block;
    padding: 12px 0;
    @include fz(11);
    text-align: center;
    color: $c-color-base;
    background: $c-color-primary;
    height: 64px;
  }
}
