@use '../global' as *;
/*===========================
/contact/
=========================== */
.contact-page {
  &__inner {
    padding: 24px 15px 40px;
    background: #fbfbfb;
    @include mq(lg) {
      padding: 35px 0 96px;
      margin: auto;
      width: 480px;
      background: $c-color-base;
    }
    &.-base {
      background: $c-color-base;
    }
  }
  &__text {
    text-align: center;
  }
  &__check {
    margin-top: 24px;
  }
  &__privacy-link {
    color: $c-color-primary;
    text-decoration: underline;
  }
  &__confirm {
    margin: 40px auto 0;
    width: 280px;
    button {
      margin-top: 24px;
    }
  }
  &__thanks {
    text-align: center;
  }
}

.border-line-item {
  &__title {
    margin-top: 24px;
    @include mq(lg) {
      margin-top: 40px;
    }
  }
  &__confirm-title {
    @include fz(12);
    font-weight: bold;
    color: $c-color-text-gray;
  }
  &__confirm-content {
    margin-top: 5px;
    @include fz(12);
  }
  &__label {
    margin-right: 10px;
    font-weight: bold;
  }
  &__input {
    background: $c-color-base;
    border: 1px solid #bbb;
    border-radius: 4px;
    padding: 14px 18px;
    width: 100%;
    @include fz(16);
    &::placeholder {
      color: #bbb;
    }
    &.-select {
      position: relative;
      &::before {
        position: absolute;
        top: 0;
        right: 0;
        @include arrow(10, 2, $c-color-primaryDark, bottom);
      }
    }
    &.-radio {
      padding-left: 2em;
      text-indent: -2em;
      &:not(:first-child) {
        margin-top: 16px;
      }
    }
    &.-checkbox {
      padding-left: 2em;
      text-indent: -2em;
      width: 100%;
      display: block;
      &:not(:first-child) {
        margin-top: 16px;
      }
    }
  }
  &__body {
    margin-top: 24px;
  }
  &__content {
    margin-top: 8px;
  }
  &__confirm-list {
    margin-top: 24px;
    border-bottom: 1px solid $c-color-border;
  }
  &__item {
    padding: 16px 0;
    border-top: 1px solid $c-color-border;
  }
}

.required-label {
  padding: 3px;
  background: $c-color-secondary;
  font-family: Hiragino Kaku Gothic Pro sans-serif;
  @include fz(10);
  border-radius: 2px;
  vertical-align: text-top;
  &.-optional {
    background: $c-color-border;
    color: #666;
  }
}

input.contact-error,
select.contact-error {
  border: 1px solid $c-color-caution !important;
  background: #fae6e8 !important;
}

.contact-error {
  margin-top: 8px;
  color: $c-color-caution;
}

.radio-input {
  visibility: hidden;
  &__text {
    padding-left: 30px;
    position: relative;
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 20px;
      height: 20px;
      border: 2px solid #999;
      border-radius: 50%;
    }
  }
  &:checked {
    background: $c-color-base;
    + .radio-input__text::after {
      content: '';
      display: block;
      position: absolute;
      top: 5px;
      left: 5px;
      width: 10px;
      height: 10px;
      background: $c-color-primary;
      border-radius: 50%;
    }
    + .radio-input__text::before {
      border: 2px solid $c-color-primary;
    }
  }
}

.checkbox-input {
  visibility: hidden;
  &__text {
    padding-left: 30px;
    position: relative;
    &::before {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 18px;
      height: 18px;
      border: 2px solid #999;
      border-radius: 2px;
    }
  }
  &:checked {
    background: $c-color-primary;
    + .checkbox-input__text::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 5px;
      width: 7px;
      height: 13px;
      transform: rotate(40deg);
      border-bottom: 2px solid $c-color-base;
      border-right: 2px solid $c-color-base;
    }
    + .checkbox-input__text::before {
      background: $c-color-primary;
      border: 2px solid $c-color-primary;
    }
  }
}
