@use '../global' as *;

html {
  text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent; //タップの強調色解除
  /* フォントサイズの正確に */
  -webkit-text-size-adjust: 100%;
  /* 文字をシャープに */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: -webkit-fill-available;
}

body {
  font-family: 'Noto Sans CJK JP', 'NotoSansCJKjp', 'Noto Sans',
    'ヒラギノ角ゴ Pro', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'Meiryo',
    sans-serif;
  font-feature-settings: 'palt' 1;
  line-height: 1.5;
  overflow-x: hidden;
}

ul,
li {
  list-style: none;
}

a {
  color: $c-color-text;
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
  vertical-align: bottom;
}

select {
  appearance: none;
}

// スクリーンリーダー非表示
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  border: 0;
}

.pc {
  display: none;
  @include mq(lg) {
    display: block;
  }
}
.sp {
  display: block;
  @include mq(lg) {
    display: none;
  }
}

.icon {
  font-variation-settings: 'FILL' 0, 'wght' 100, 'GRAD' 0, 'opsz' 48;
}

// マテリアルアイコン
//https://fonts.google.com/icons?selected=Material+Icons
%material-icons {
  font-weight: normal;
  font-style: normal;
  @include fz(24); /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: 'liga';
}

.material-icons {
  font-family: 'Material Icons';
  @extend %material-icons;
}
.material-icons-round {
  font-family: 'Material Icons Round';
  @extend %material-icons;
}
.material-icons-outlined {
  font-family: 'Material Icons Outlined';

  @extend %material-icons;
}
.material-icons-sharp {
  font-family: 'Material Icons Sharp';
  @extend %material-icons;
}
