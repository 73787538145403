@use '../global' as *;
body.modal-fixed {
  position: fixed;
  left: 0;
  width: 100%;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}
//モーダル共通
.modal {
  display: none; /* 初期状態 */
  position: fixed;
  top: 0;
  right: 0;
  width: 100%; /* モーダルは画面いっぱいに */
  height: 100vh; /* モーダルは画面いっぱいに */
  z-index: 150;
  &.is-active {
    display: block;
  }
  &__bg {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
  }
  &__window {
    position: absolute;
    background-color: #fff;
    border-radius: 0;
    top: 0;
    left: 50%;
    transform: translate(-50%);
    width: 100%;
    @include mq(lg) {
      position: absolute;
      background-color: $c-color-base;
      border-radius: 8px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 600px;
      &.-shop-list {
        width: 900px;
      }
    }
  }
  &__title {
    padding: 18px;
    text-align: center;
    font-weight: 600;
    background: $c-color-base;
    color: $c-color-text;
    @include fz(16);
    border-radius: 0;
    border-bottom: 1px solid $c-color-border;
    @include mq(lg) {
      padding: 15px;
      background: $c-color-primary;
      color: $c-color-base;
      @include fz(20);
      border-radius: 8px 8px 0 0;
      border-bottom: none;
    }
  }
  &__content {
    background: $c-color-base;
    padding: 24px 16px;
    height: 100vh;
    overflow-y: scroll;
    max-height: calc(100vh - 58px);
    &.-shop-list {
      padding: 0;
    }
    @include mq(lg) {
      padding: 40px;
      border-radius: 0 0 8px 8px;
      overflow: scroll;
      max-height: 75vh;
      &.-shop-list {
        padding: 40px;
      }
    }
  }
}

.js-modal-close {
  position: absolute;
  display: block;
  width: 60px;
  height: 60px;
  z-index: 200;
  right: 0;
  &__box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 20px;
    width: 30px;
  }
}

.js-modal-close {
  & .js-ham-stripe {
    top: 0;
    width: 30px;
    height: 3px;
    background-color: #0060af;
    transition-duration: 0.25s;
    transition-timing-function: linear;
    @include mq(lg) {
      background-color: $c-color-base;
    }
    &::before,
    &::after {
      position: relative;
      content: '';
      display: block;
      width: 30px;
      height: 3px;
      background-color: #0060af;
      transition-duration: 0.25s;
      transition-timing-function: linear;
      @include mq(lg) {
        background-color: $c-color-base;
      }
    }
    &::before {
      top: 8px;
    }
    &::after {
      top: 13px;
    }
  }
}

// X
.js-modal-close {
  & .js-ham-stripe {
    transform: translate3d(0, 8px, 0) rotate(45deg);
    &::before {
      transform: translate3d(0, -8px, 0) rotate(-45deg);
      opacity: 0;
    }
    &::after {
      transform: translate3d(0, -15px, 0) rotate(-90deg);
    }
  }
}
