@use '../global' as *;

/*===========================
/service/
=========================== */
.service-page {
  &__section {
    @include content-wrap();
    margin-top: 54px;
  }
}

.normal-txt {
  @include fz(12);
  display: block;
  margin-bottom: 24px;

  @include mq(md) {
    @include fz(14);
  }
}

.service-header__logo {
  img {
    height: 41px;
    display: block;
    margin: 0 auto 16px;
  }

  @include mq(md) {
    img {
      // width: 248px;
      margin-bottom: 20px;
    }
  }
}

.service-header {
  padding: 0 26.5px;
}

.content-body {
  margin-bottom: 70px;

  @include mq(md) {
    margin-bottom: 5px;
  }
}

.side-img_badge01 {
  @include fz(24);
  font-weight: bold;
  display: inline-block;
  padding: 6px 33px;
  margin-bottom: 16px;

  &.--crystal {
    background-color: #4fa6d3;
  }

  &.--diamond {
    background-color: #bfa548;
  }

  &.--wdia {
    background-color: #024094;
    color: #fff;
  }

  &.--pure {
    background-color: #eb6100;
  }

  span {
    @include fz(14);
    margin-left: 5px;
  }
}

.side-img_badge02 {
  @include fz(12);
  background-color: #222;
  color: #fff;
  width: 140px;
  padding: 5px 0;
  text-align: center;
  margin-bottom: 10px;

  @include mq(md) {
    @include fz(14);
    width: 158px;
    padding: 5px 0;
  }
}

.side-img_heading {
  @include fz(30);
  margin-bottom: 10px;
  font-weight: bold;
  font-family: 'TBUDGothic Std', 'Noto Sans CJK JP', 'NotoSansCJKjp',
    'Noto Sans', 'ヒラギノ角ゴ Pro', 'Hiragino Kaku Gothic Pro', 'メイリオ',
    'Meiryo', sans-serif;

  &.--crystal {
    color: #4fa6d3;
  }

  &.--diamond {
    color: #bfa548;
  }

  &.--wdia {
    color: #084796;
  }

  &.--pure {
    color: #eb6100;
  }

  @include mq(md) {
    @include fz(40);
  }
}

.side-img_txt {
  @include fz(16);
  margin-bottom: 40px;

  @include mq(md) {
    @include fz(20);
    line-height: 1.75;
  }
}

.side-img {
  @include mq(md) {
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
  }
}

.side-img_after {
  flex: 1;

  img {
    width: 100%;
  }

  @include mq(md) {
    max-width: 459px;

    img {
      width: 100%;
    }
  }
}

@include mq(md) {
  .side-img_before {
    flex: 1;
  }
}

.bgc-card-unit {
  margin-top: 40px;
  display: grid;
  gap: 15px;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, 156px);
  margin-bottom: 40px;

  @include mq(md) {
    gap: 20px;
    margin-bottom: 56px;
    margin-top: 5px;
    grid-template-columns: repeat(auto-fit, 220px);
  }
}

.bgc-card {
  background-color: #003662;
  text-align: center;
  color: #fff;
  padding: 16px;
}

.bgc-card_heading {
  background-color: #fff;
  color: #003662;
  @include fz(16);
  font-weight: bold;
  padding: 6px 0;
}

.bgc-card_txt {
  line-height: 1.8;
  text-align: left;
  @include fz(11);
  font-weight: normal;

  @include mq(md) {
    @include fz(14);
    line-height: 1.75;
    letter-spacing: 0.1em;
  }
}

.bgc-card_icon {
  padding-top: 10px;
  padding-bottom: 10px;

  @include mq(md) {
    img {
      width: 80px;
    }
  }
}

@include mq(md) {
  .btn-wrapper {
    width: 400px;
    margin: 0 auto;
  }
}

.tableset01 {
  margin-top: 40px;

  @include mq(md) {
    margin-top: 0px;
  }
}

.tableset01_heading {
  @include fz(11);
  margin-bottom: 15px;

  @include mq(md) {
    @include fz(16);
  }
}

@include mq(md) {
  .tableset01_body {
    display: flex;
    align-items: flex-start;

    img {
      margin-left: 4%;
    }
  }
}

.tableset01_img {
  width: 100%;

  @include mq(md) {
    width: 220px;
  }
}
