@use '../global' as *;
/*===========================
/order/
=========================== */
.order-page {
  &__flow {
    margin: 24px 12px;
    @include mq(lg) {
      @include content-wrap();
      margin-top: 40px;
    }
  }
  &__text {
    @include content-wrap();
    margin-top: 24px;
    @include mq(lg) {
      margin-top: 40px;
      @include fz(14);
    }
  }
}

// フロー
.order-flow {
  display: flex;
  justify-content: center;
  text-align: center;
  position: relative;
  &__item {
    position: relative;
    width: 100%;
    @include fz(12);
    font-weight: bold;
    color: #b4b4b4;
    &::before {
      display: block;
      width: 18px;
      height: 18px;
      margin: 0.5em auto;
      content: '';
      text-align: center;
      border-radius: 50%;
      background-color: #b4b4b4;
      border: 1px solid #b4b4b4;
    }
    &::after {
      position: absolute;
      z-index: -1;
      top: 1em;
      left: 50%;
      width: 100%;
      height: 2px;
      content: '';
      background-color: #b4b4b4;
      transform: translateX(-50%);
    }
    &:last-child {
      &::after {
        background: linear-gradient(to right, #b4b4b4 50%, $c-color-base 50%);
      }
    }
    &:first-child.is-active {
      &::after {
        background: linear-gradient(
          to right,
          $c-color-base 50%,
          $c-color-primary 50%
        );
      }
    }
    &.is-active {
      color: $c-color-text;
      &::before {
        width: 18px;
        height: 18px;
        margin: 0.5em auto;
        content: '';
        text-align: center;
        border-radius: 50%;
        background-color: $c-color-base;
        border: 5px solid $c-color-primary;
      }
      &::after {
        background: $c-color-primary;
      }
    }
    &:last-child.is-active {
      &::after {
        background: linear-gradient(
          to right,
          $c-color-primary 50%,
          $c-color-base 50%
        );
      }
    }
  }
}

@media (min-width: 940px) {
  .order-flow {
    margin-bottom: 20px;
  }
}
