@use '../global' as *;
/*===========================
/company/
=========================== */
//会社概要
.company-page {
  @include content-wrap();
  margin-top: 24px;
  margin-bottom: 90px;
  @include mq(lg) {
    width: 620px;
    margin: 40px auto 90px;
  }
  &__link {
    margin-top: 40px;
    @include mq(lg) {
      margin: 40px auto 0;
      width: 380px;
    }
  }
  &__link-text {
    vertical-align: middle;
    display: flex;
    align-items: center;
    & .material-icons {
      @include fz(14);
    }
    @include mq(lg) {
      @include fz(16);
    }
  }
  &__category {
    @include mq(lg) {
      margin-top: 40px;
    }
  }
}
