@use '../global' as *;
/*===========================
/privacy/
=========================== */
//個人情報取り扱いについて
.privacy-page {
  @include content-wrap();
  margin-bottom: 24px;
  margin-bottom: 90px;
  @include mq(lg) {
    margin-top: 40px;
    width: 620px;
  }
}

.privacy {
  &__title {
    margin: 40px 0 8px;
    font-weight: bold;
    @include mq(lg) {
      margin: 40px 0 12px;
    }
  }
  &__text {
    margin-top: 16px;
  }
}
