@use '../global' as *;
//タブ
.c-local-nav {
  background-color: $c-color-primary;

  &__inner {
    display: flex;
    justify-content: center;
    @include mq(lg) {
      margin: auto;
      width: 940px;
      max-width: 100%;
      border-left: 2px solid rgba($c-color-base, 0.5);
      border-right: 2px solid rgba($c-color-base, 0.5);
    }
  }
  &__item {
    cursor: pointer;
    position: relative;
    padding: 11px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100% / 3);
    text-align: center;
    @include fz(16);
    font-weight: bold;
    color: rgba($c-color-base, 0.5);
    @include mq(lg) {
      padding: 20px 0;
      flex-direction: row;
      @include fz(24);
    }
    &::before {
      margin-bottom: 10px;
      @include mq(lg) {
        margin: 0 10px 0 0;
      }
    }
    &.-tab1 {
      &::before {
        display: block;
        content: '';
        background: url('/_files/_keeper/images/tab_icon1.svg');
        width: 31px;
        height: 30px;
        background-size: contain;
        @include mq(lg) {
          width: 52px;
          height: 49px;
        }
      }
    }
    &.-tab2 {
      &::before {
        display: block;
        content: '';
        background: url('/_files/_keeper/images/tab_icon2.svg');
        width: 38px;
        height: 30px;
        background-size: contain;
        @include mq(lg) {
          width: 63px;
          height: 48px;
        }
      }
    }
    &.-tab3 {
      &::before {
        display: block;
        content: '';
        background: url('/_files/_keeper/images/tab_icon3.svg');
        width: 31px;
        height: 34px;
        background-size: contain;
        @include mq(lg) {
          width: 47px;
          height: 51px;
        }
      }
    }

    &.-active {
      color: #fff;
      background-color: #007ce2;
      cursor: pointer;
      &::after {
        position: absolute;
        bottom: -1px;
        content: '';
        display: block;
        border-bottom: 10px solid #fff;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
      }
    }
    &:not(:first-child) {
      border-left: 2px solid rgba($c-color-base, 0.5);
    }
  }
  &__content {
    display: none;
    padding-top: 54px;
    @include mq(lg) {
      padding-top: 92px;
    }
    &.-show {
      display: block;
    }
  }
}
