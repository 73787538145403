@use '../global' as *;
// CTA
.c-cta {
  padding: 54px 15px;
  background: $c-color-primary;
  @include mq(lg) {
    padding: 95px 0;
  }
  &__inner {
    padding: 40px 16px;
    background: $c-color-base;
    @include mq(lg) {
      padding: 40px 60px;
      margin: auto;
      width: 940px;
    }
  }
  &__img {
    display: block;
    margin: 0 auto 10px;
    @include mq(lg) {
      width: 210px;
      height: 192px;
    }
  }
  &__logo {
    display: block;
    margin: auto;
    width: 111px;
    height: 40px;
    @include mq(lg) {
      width: 150px;
      height: 54px;
    }
  }
  &__text-box {
    margin-top: 10px;
    font-weight: bold;
    text-align: center;
    @include mq(lg) {
      margin-top: 40px;
      @include fz(24);
      &.-top {
        margin-top: 24px;
      }
    }
  }
  &__text-em {
    margin-bottom: 8px;
    color: $c-color-primary;
    @include fz(12);
    @include mq(lg) {
      margin-bottom: 16px;
      @include fz(16);
    }
  }
  &__text {
    @include fz(15);
    @include mq(lg) {
      @include fz(24);
    }
  }
  &__link-box {
    @include mq(lg) {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      &.-top {
        margin-top: 0;
      }
    }
  }
  &__link {
    margin-top: 24px;
    @include mq(lg) {
      &:not(:first-child) {
        margin-left: 20px;
      }
    }
  }
  &__note {
    margin-top: 24px;
    @include fz(11);
    text-align: center;
  }
}
