@use '../global' as *;

.floating-bnr {
  padding: 9px 0.5rem 8px;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: $c-color-base;
  z-index: 90;
  @include mq(lg) {
    padding: 19px 0 18px;
  }
  &.-stop {
    position: relative;
  }
  &__inner {
    display: flex;
    justify-content: center;
  }
  &__item {
    width: 100%;
    @include mq(lg) {
      width: 400px;
    }
    &:last-of-type {
      margin-left: 0.5rem;
      @include mq(lg) {
        margin-left: 20px;
      }
    }
  }
  & .btn {
    @include mq(lg) {
      padding: 13px 0;
      @include fz(18);
    }
  }
  &__icon {
    display: block;
    margin: 0 auto 8px;
  }
  &__link {
    display: block;
    padding: 12px 0;
    @include fz(11);
    text-align: center;
    color: $c-color-base;
    background: $c-color-primary;
    height: 64px;
  }
}
