@use '../global' as *;

// 店舗一覧
.c-shop-list {
  &__area-title {
    background: $c-color-bg;
    width: 100%;
    padding: 11px 15px;
    @include fz(14);
    &:not(:first-of-type) {
      margin-top: 40px;
    }
  }
  &__item {
    width: 100%;
    &:last-child {
      border-bottom: 1px solid $c-color-border;
    }
  }
}

.c-shop-item {
  border-top: 1px solid $c-color-border;
  display: block;
  padding: 15px;
  &:hover .btn {
    background: #fea827;
  }

  &__inner {
    display: flex;
  }
  &__img {
    margin-right: 12px;
    max-width: 90px;
    height: 90px;
    flex-basis: 120px;
    flex-shrink: 0;
    flex-grow: 0;
    @include mq(lg) {
      margin-right: 16px;
      max-width: 120px;
      height: 90px;
    }
  }
  &__shop {
    width: 100%;
    font-weight: bold;
    @include fz(16);
    @include mq(lg) {
      width: 100%;
    }
  }
  &__address {
    margin-top: 4px;
    display: block;
    @include fz(14);
    font-weight: normal;
  }
  &__business-hours {
    margin-top: 4px;
  }
  &__time {
    @include fz(12);
    color: $c-color-text-gray;
    &::before {
      margin-right: 5px;
      content: '';
      display: inline-block;
      background: url(/_files/_keeper/images/ico_time.svg) no-repeat;
      width: 13px;
      height: 15px;
      background-size: contain;
      vertical-align: middle;
    }
  }
  &__link {
    margin-top: 12px;
    > .btn {
      padding: 7px 0 6px;
      @include fz(13);
    }
    @include mq(lg) {
      margin-top: 0;
      display: flex;
      align-items: center;
      width: 160px;
      > .btn {
        padding: 11px 0 10px;
        @include fz(13);
      }
    }
  }
  &__box {
    width: 100%;
    @include mq(lg) {
      display: flex;
      justify-content: space-between;
    }
  }
}
