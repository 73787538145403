@use '../global' as *;

.c-pagenation {
  &__text {
    padding-bottom: 8px;
    @include fz(12);
    font-weight: 300;
    letter-spacing: 0.5px;
    @include mq(lg) {
      @include fz(14);
    }
  }
  &__text-em {
    font-weight: bold;
  }
  &__link {
    &:last-child {
      margin-left: 16px;
    }
  }
  &__list {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    @include mq(lg) {
      justify-content: flex-end;
    }
  }
  &__item {
    @include mq(lg) {
      @include fz(14);
    }
    &:last-child {
      margin-left: 20px;
    }
  }
}

.previous-link {
  display: inline-block;
  padding: 7px 14px;
  border-radius: 4px;
  font-weight: bold;
  border: 1px solid $c-color-border;
  background: $c-color-base;
  color: $c-color-primary;
  &::before {
    margin-right: -1px;
    @include arrow(9, 2, $c-color-primary, left);
  }
  &.-disabled {
    pointer-events: none;
    background: #eee;
    &::before {
      border-color: $c-color-border;
    }
  }
}

.next-link {
  display: inline-block;
  padding: 7px 14px;
  border-radius: 4px;
  font-weight: bold;
  border: 1px solid $c-color-border;
  background: $c-color-base;
  color: $c-color-primary;
  &::before {
    margin-left: -1px;
    @include arrow(9, 2, $c-color-primary, right);
  }
  &.-disabled {
    pointer-events: none;
    background: #eee;
    &::before {
      border-color: $c-color-border;
    }
  }
}
