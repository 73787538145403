@use 'variables' as *;

// メディアクエリ
@mixin mq($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media screen and #{inspect(map-get($breakpoints, $breakpoint))} {
      @content;
    }
  }
}

@mixin fz($font-size-px, $root-font-size: 16) {
  font-size: calc(($font-size-px / $root-font-size) * 1rem);
}

//ベースフォントrem変換 pタグ14px,pc16px
p {
  @include fz(14);
  @include mq(lg) {
    @include fz(16);
  }
}

@mixin roboto-regular {
  font-family: 'RobotoMonoRegular', sans-serif;
}

@mixin NotoSans {
  font-family: 'NotoSansCJKjp', sans-serif;
}

// 疑似要素初期化
@mixin icon-ini {
  content: '';
  display: inline-block;
}

// 矢印
@mixin arrow($width, $bold, $color, $deg) {
  @include icon-ini;
  border-color: $color;
  border-style: solid;
  border-width: 0 #{$bold}px #{$bold}px 0;
  height: #{$width}px;
  vertical-align: middle;
  width: #{$width}px;
  @if ($deg == 'left') {
    transform: rotate(135deg);
  } @else if ($deg == 'top') {
    transform: rotate(225deg);
  } @else if ($deg == 'right') {
    transform: rotate(-45deg);
  } @else if ($deg == 'bottom') {
    transform: rotate(45deg);
  }
}

//
@mixin content-wrap {
  margin: 0 15px;
  @include mq(lg) {
    width: 940px;
    margin-left: auto;
    margin-right: auto;
  }
}

@mixin clearfix {
  &::after {
    content: '';
    display: block;
    clear: both;
  }
}

@mixin shadow-box {
  background: $c-color-base;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.14);
  border-radius: 8px;
}
