@use '../global'as *;

.blue_borderbox {
  border: 2px solid #0060AF;
  // width: 168px;
  text-align: center;
  color: #0060AF;
  padding: 4px 10px;
  margin: 0 auto 24px;
  display: inline-block;

  @include mq(md) {
    margin-bottom: 40px;
  }

  .material-icons-outlined {
    vertical-align: middle;
  }
}

.blue_borderbox_wrapper {
  text-align: center;

  @include mq(md) {
    text-align: left;
  }
}
