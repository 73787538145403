@use '../global' as *;
//実績のカード
.card02-unit {
  margin-top: 40px;
  margin-bottom: 40px;

  display: grid;
  gap: 24px 25px;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, 150px);

  @include mq(md) {
    margin-top: 30px;
    margin-bottom: 30px;
    gap: 32px 46px;
    grid-template-columns: repeat(auto-fit, 200px);
  }
}

.card02 {
  display: block;
  transition: $c-transition;
  transition-property: opacity;
  &:hover {
    opacity: 0.8;
  }
}

.card02__img-wrapper {
  img {
    display: block;
    margin-bottom: 5px;
  }

  @include mq(md) {
    img {
      width: 100%;
    }
  }
}

.card02__heading {
  @include fz(12);
  font-weight: bold;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  margin-bottom: 2px;

  @include mq(md) {
    @include fz(14);
    margin-bottom: 0;
    line-height: 1.6;
  }
}

.card02__price {
  font-weight: bold;
  color: #e60012;
  @include fz(12);

  @include mq(md) {
    @include fz(14);

    span {
      @include fz(10);
    }
  }

  span {
    @include fz(9);
    font-weight: normal;
    padding: 0 4px;
    margin-right: 2px;
    background-color: #e60012;
    display: inline-block;
    color: #fff;
  }
}

.dl-list {
  @include fz(9);
  color: 666;
  line-height: 2;

  @include mq(md) {
    @include fz(11);
  }

  span {
    color: #3b7de9;
  }

  div {
    display: flex;

    dt {
      &::after {
        content: '：';
      }
    }
  }
}
