// color
$c-color-primary: #0060af;
$c-color-primaryDark: #003662;
$c-color-primaryLight: #92aff2;
$c-color-primaryMoreLight: #f2f5ff;

$c-color-secondary: #ffc631;
$c-color-secondaryDark: #e3a500;
$c-color-secondaryLight: #ffdb7d;
$c-color-secondaryMoreLight: #fff7e3;

$c-color-tertiary: #d2612a;
$c-color-tertiaryDark: #e3a500;

$c-color-border: #cccccc;
$c-color-bg-light: #eeeeee;

$c-color-bg: #f0f1f3;

$c-color-text: #222222;
$c-color-text-gray: #666666;

$c-color-link: #3b7de9;
$c-color-disabled: #d4d8dd;

$c-color-base: #fff;

$c-color-caution: #e60012;
$c-color-bgError: #ffe6e8;

$c-color-success: #08be8e;
$c-color-bgSuccess: #ecfdf8;

$c-color-attention: #ffc415;
$c-color-bgAttention: #fff4d5;

//sns
$color-twitter: #55acee;
$color-facebook: #3b5998;
$color-line: #00c200;

$c-color-light: #f2f5ff;
$c-color-label: #999;
$c-color-border2: #e8e8e8;

$c-transition: 0.2s;

// パス
$imgPath: '../images/';
$fontsPath: '../fonts/';

// ブレイクポイント
$breakpoints: (
  'xs': (
    min-width: 320px,
  ),
  's': (
    min-width: 375px,
  ),
  'sm': (
    min-width: 520px,
  ),
  'md': (
    min-width: 768px,
  ),
  'lg': (
    min-width: 940px,
  ),
  'tab': (
    min-width: 1024px,
  ),
  'xl': (
    min-width: 1366px,
  ),
  'pc-md': (
    min-width: 1536px,
  ),
  'pc-lg': (
    min-width: 1920px,
  ),
) !default;

@font-face {
  font-family: 'RobotoMonoRegular';
  src: local('Roboto Mono Regular'),
    url('#{$fontsPath}RobotoMonoRegular.woff2') format('woff2'),
    url('#{$fontsPath}RobotoMonoRegular.woff') format('woff'),
    url('#{$fontsPath}RobotoMonoRegular.ttf') format('truetype');
  font-display: swap;
  font-weight: 400;
}

@font-face {
  font-family: 'NotoSansCJKjp';
  font-style: normal;
  font-weight: 400;
  src: local('Noto Sans CJK JP'), local('NotoSansCJKjp-Regular'),
    local('NotoSansJP-Regular'),
    url('#{$fontsPath}NotoSansCJKjp-Regular.eot') format('eot'),
    url('#{$fontsPath}NotoSansCJKjp-Regular.ttf') format('ttf'),
    url('#{$fontsPath}NotoSansCJKjp-Regular.woff2') format('woff2'),
    url('#{$fontsPath}NotoSansCJKjp-Regular.woff') format('woff');
}

@font-face {
  font-family: 'NotoSansCJKjp';
  font-style: normal;
  font-weight: 700;
  src: local('NotoSansCJKjp-Bold'), local('NotoSansJP-Bold'),
    url('#{$fontsPath}NotoSansCJKjp-Bold.eot') format('eot'),
    url('#{$fontsPath}NotoSansCJKjp-Bold.ttf') format('ttf'),
    url('#{$fontsPath}NotoSansCJKjp-Bold.woff2') format('woff2'),
    url('#{$fontsPath}NotoSansCJKjp-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons'),
    url('#{$fontsPath}/MaterialIcons/MaterialIcons-Regular.ttf') format('ttf'),
    url('#{$fontsPath}/MaterialIcons/MaterialIcons-Regular.woff2')
      format('woff2'),
    url('#{$fontsPath}/MaterialIcons/MaterialIcons-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Material Icons Outlined';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons Outlined'),
    url('#{$fontsPath}/MaterialIcons/MaterialIconsOutlined-Regular.otf')
      format('ttf'),
    url('#{$fontsPath}/MaterialIcons/MaterialIconsOutlined-Regular.woff2')
      format('woff2'),
    url('#{$fontsPath}/MaterialIcons/MaterialIconsOutlined-Regular.woff')
      format('woff');
}

@font-face {
  font-family: 'Material Icons Round';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons Round'),
    url('#{$fontsPath}/MaterialIcons/MaterialIconsRound-Regular.otf')
      format('ttf'),
    url('#{$fontsPath}/MaterialIcons/MaterialIconsRound-Regular.woff2')
      format('woff2'),
    url('#{$fontsPath}/MaterialIcons/MaterialIconsRound-Regular.woff')
      format('woff');
}

@font-face {
  font-family: 'Material Icons Sharp';
  font-style: normal;
  font-weight: 400;
  src: local('Material Icons Sharp'),
    url('#{$fontsPath}/MaterialIcons/MaterialIconsSharp-Regular.otf')
      format('ttf'),
    url('#{$fontsPath}/MaterialIcons/MaterialIconsSharp-Regular.woff2')
      format('woff2'),
    url('#{$fontsPath}/MaterialIcons/MaterialIconsSharp-Regular.woff')
      format('woff');
}
