@use '../global' as *;

// フローティングバナー分
.l-footer-wrap {
  &.is-active {
    padding-bottom: 60px;
  }
  @include mq(lg) {
    &.is-active {
      padding-bottom: 90px;
    }
  }
}

.sns-item {
  display: flex;
  width: 178px;
  justify-content: space-between;
  @include mq(lg) {
    margin-top: 40px;
  }
}

.fb_iframe_widget {
  display: inherit !important;
}

.pagination {
  &__list {
    @include fz(11);
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $c-color-border;
    border-bottom: 1px solid $c-color-border;
  }
  &__item {
    position: relative;
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    &:not(:first-child) {
      .pagination__link {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    &:not(:first-child) {
      &::before {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transition: translete;
        content: '';
        display: block;
        width: 1px;
        height: 25%;
        background: $c-color-border;
      }
    }
    &.-prev {
      &::before {
        @include arrow(8, 2, $c-color-primary, left);
      }
    }
    &.-next {
      &::after {
        @include arrow(8, 2, $c-color-primary, right);
      }
    }
  }
  &__link {
    width: 100%;
    padding: 8px;
    display: flex;
    align-items: center;
    padding: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__text {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
  }
  &__arrow-right {
    margin-left: auto;
  }
}

.c-footer-nav {
  margin: 54px 0;
  @include mq(lg) {
    margin: 96px 0;
  }
  &__list {
    margin: 0 15px;
    @include mq(lg) {
      margin: 96px 0;
    }
  }
  &__item {
    &:not(:first-child) {
      margin-top: 16px;
    }
  }
  &__link {
    display: block;
    padding: 9px 15px;
    border: 1px solid $c-color-border;
    width: 100%;
    text-align: center;
  }
}

.footer {
  background: $c-color-bg;
  &__inner {
    padding: 40px 15px 0;
    @include mq(lg) {
      padding: 60px 0 40px;
    }
  }
  &__bottom {
    @include mq(lg) {
      text-align: center;
    }
  }
  &__img-block {
    margin-top: 24px;
    text-align: center;
    @include mq(lg) {
      margin-top: 40px;
    }
  }
  &__logo-img {
    @include mq(lg) {
      width: 146px;
    }
  }
  &__copyright {
    color: $c-color-text-gray;
    border-top: 1px solid $c-color-border;
    padding: 16px 0;
    margin-top: 24px;
    @include fz(10);
    text-align: center;
    @include mq(lg) {
      @include fz(12);
      padding: 0;
      border: none;
    }
  }
}

.sns {
  display: flex;
  justify-content: center;
  @include mq(lg) {
    justify-content: center;
  }
  &__item {
    &:not(:first-of-type) {
      margin-left: 10px;
    }
  }
  &__link {
    display: block;
  }
}

.pc-sitemap {
  @include mq(lg) {
    display: flex;
    justify-content: center;
    @include fz(12);
    padding-bottom: 66px;
    &__list {
      &:not(:first-of-type) {
        margin-left: 97px;
      }
    }
    &__list-title {
      font-weight: bold;
    }
    &__link-list {
      margin-top: 26px;
    }
    &__link-item {
      &:not(:first-of-type) {
        margin-top: 20px;
      }
    }
    &__link {
      padding-left: 15px;
      &::before {
        margin-left: -15px;
        display: inline-block;
        content: '';
        background: url('/_files/_keeper/images/ico_black-right-arrow.svg')
          no-repeat;
        width: 16px;
        height: 10px;
        background-size: contain;
        vertical-align: middle;
      }
    }
  }
}

.page-top {
  border-top: 1px solid $c-color-border;
  border-bottom: 1px solid $c-color-border;
  &__link {
    background: $c-color-base;
    display: block;
    cursor: pointer;
    padding: 17px 0;
    text-align: center;
    @include fz(12);
  }
}
