@use '../global'as *;


.table-price01 {
  width: 100%;
  @include fz(11);
  table-layout: fixed;
  border-collapse: collapse;
  margin-bottom: 40px;

  @include mq(md) {
    table-layout: auto;
    margin-bottom: 55px;
  }

  thead {
    background-color: #F0F1F3;
  }

  th {
    @include mq(md) {
      @include fz(12);
      padding: 11px 12px;
    }

    text-align: left;
    font-weight: normal;
  }

  td,
  th {
    padding: 9px 8px;
    border: 1px solid #CCC;

  }

  td {
    line-height: 1.75;

    &:first-child {
      font-weight: bold;
      @include fz(14);

      @include mq(md) {
        width: 21.5%;
        @include fz(16);
      }
    }

    &:last-child {
      color: #E60012;
      text-align: right;
      font-weight: bold;
      @include fz(14);

      @include mq(md) {
        @include fz(20);
      }
    }

    @include mq(md) {
      @include fz(14);
      padding: 12px 12px;
    }
  }
}

.table-price02,
.table-price03 {
  width: 100%;
  @include fz(11);
  table-layout: fixed;
  border-collapse: collapse;
  margin-bottom: 20px;



  thead {
    background-color: #F0F1F3;
  }

  th {
    @include mq(md) {
      @include fz(12);
      padding: 11px 12px;
    }

    text-align: left;
    font-weight: normal;
  }

  td,
  th {
    padding: 6px 8px;
    border: 1px solid #CCC;
    line-height: 1.75;
  }

  td {
    line-height: 1.75;

    &:first-child {
      font-weight: bold;
      @include fz(14);

      @include mq(md) {
        @include fz(16);
      }
    }

    @include mq(md) {
      @include fz(14);
      padding: 12px 12px;
    }
  }
}

@include mq(md) {
  .tableset02 {
    display: flex;
    flex-direction: row-reverse;
    margin-bottom: 20px;
    align-items: flex-start;

    >div {
      flex: 1;
    }
  }

  .tableset02_img {
    width: 278px;
    margin-left: 24px;
  }
}

.table-price02 {
  td {

    &:last-child,
    &:nth-last-child(2) {
      color: #E60012;
      text-align: right;
      font-weight: bold;
      @include fz(14);

      @include mq(md) {
        @include fz(20);
      }
    }
  }
}

.table-price03 {
  td {
    &:last-child {
      color: #E60012;
      text-align: right;
      font-weight: bold;
      @include fz(14);

      @include mq(md) {
        @include fz(20);
      }
    }
  }
}
