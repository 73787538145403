@use '../global' as *;

.c-service-nav {
  background-color: $c-color-primary;
  &__inner {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    @include mq(lg) {
      margin: auto;
      width: 940px;
      border-left: 2px solid rgba($c-color-base, 0.5);
      border-right: 2px solid rgba($c-color-base, 0.5);
    }
  }
  &__item {
    position: relative;
    padding: 8px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: calc(100% / 2);
    text-align: center;
    @include fz(16);
    font-weight: bold;
    color: rgba($c-color-base, 0.5);
    @include mq(lg) {
      padding: 20px 0;
      flex-direction: row;
      @include fz(20);
      width: calc(100% / 4);
    }
    &::before {
      margin-bottom: 10px;
      @include mq(lg) {
        margin: 0 10px 0 0;
      }
    }
    &.-tab1,
    &.-tab2,
    &.-tab3,
    &.-tab4 {
      > svg {
        fill: rgba($c-color-base, 0.5);
        @include mq(lg) {
          margin-right: 8px;
        }
      }
    }
    &.-active {
      color: #fff;
      background-color: #007ce2;
      &.-tab1,
      .-tab2,
      .-tab3,
      .-tab4 {
        > svg {
          fill: $c-color-base;
        }
      }
      @include mq(lg) {
        &::after {
          position: absolute;
          bottom: -1px;
          content: '';
          display: block;
          border-bottom: 11px solid #fff;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
        }
      }
    }
    &:nth-child(even) {
      border-left: 2px solid rgba($c-color-base, 0.5);
    }
    &:nth-child(n + 3) {
      border-top: 2px solid rgba($c-color-base, 0.5);
      @include mq(lg) {
        border-top: none;
      }
    }
    @include mq(lg) {
      &:not(:first-child) {
        border-left: 2px solid rgba($c-color-base, 0.5);
      }
    }
  }
  &__content {
    display: none;
    &.-show {
      display: block;
    }
  }
}
