@use '../global' as *;

/*=============================================
 * <header>
 *=============================================*/
.l-header {
  position: relative;
  padding-top: 60px;
  z-index: 99;
  @include mq(lg) {
    position: sticky;
    top: 0;
    padding-top: 88px;
  }
  &__nav {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
    width: 100%;
    height: 60px;
    background: $c-color-base;
  }
  &__nav-bg {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 1000vh;
    background: rgba(#194276, 0.24);
    z-index: 100;
    @include mq(lg) {
      display: none;
    }
  }
  &__nav-inner {
    border-bottom: 1px solid $c-color-border;
    background: $c-color-base;
    height: 60px;
    position: fixed;
    display: flex;
    width: 100%;
    z-index: 200;
    @include mq(lg) {
      position: relative;
      height: auto;
      align-items: center;
      height: 88px;
    }
  }
  &__logo {
    @include mq(lg) {
      flex-shrink: 0;
    }
  }
  &__logo-img {
    display: block;
    width: 110px;
    height: 40px;
  }
  &__logo-link {
    display: block;
    padding: 11px 0 11px 1rem;
    width: 100%;
    box-sizing: content-box;
    @include mq(lg) {
      padding: 24px 1rem;
    }
  }
  &__right {
    display: flex;
    margin-left: auto;
    @include mq(lg) {
      order: 3;
    }
  }
  &__nav-item {
    margin-top: 60px;
    padding-bottom: 60px;
    overflow-y: scroll;
    position: fixed;
    top: 0;
    right: 0;
    width: 80%;
    height: 100vh;
    background: $c-color-base;
    z-index: 199;
    @include mq(lg) {
      margin: 0;
      padding: 0;
      overflow-y: inherit;
      position: inherit;
      width: 100%;
      // display: flex;
      background-color: transparent;
      height: 88px;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }
}

.l-header-btn {
  display: flex;
  &__link {
    padding: 12px 0;
    display: block;
    font-weight: bold;
    text-align: center;
    line-height: 16px;
    color: #fff;
    width: 60px;
    position: relative;
    transition: all 0.3s;
    background: $c-color-primary;
    &:hover {
      background: lighten($c-color-primary, 10%);
    }
    &.-reserve {
      background: $c-color-tertiary;
      &:hover {
        background: lighten($c-color-tertiary, 10%);
      }
    }
    @include mq(lg) {
      padding: 18px 0;
      width: 120px;
    }
  }
  &__text {
    display: block;
    @include fz(10);
    transform: scale(0.9);
    transform-origin: center top;
    @include mq(lg) {
      @include fz(14);
    }
  }
  &__ico {
    display: block;
    margin: 0 auto 4px;
    @include fz(16);
    @include mq(lg) {
      @include fz(32);
    }
  }
}

.l-hamburger {
  position: relative;
  display: block;
  width: 60px;
  height: 60px;
  z-index: 200;
  @include mq(lg) {
    display: none;
  }
  &__box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 20px;
    width: 30px;
  }
}

.l-nav {
  border-bottom: 1px solid $c-color-border;
  @include mq(lg) {
    display: grid;
    grid-template-columns: 11vw repeat(auto-fit, 8.5vw);
    align-items: center;
    border-bottom: none;
    height: 88px;
    justify-content: flex-end;
  }
  &__item {
    position: relative;
    border-top: 1px solid $c-color-border;
    @include mq(lg) {
      border-top: none;
      word-break: keep-all;
      &:first-child {
        > .l-nav__item-link.-accordion {
          border-radius: 6px 6px 0 0;
        }
      }
      &:last-child {
        > .l-nav__item-link.-accordion {
          border-radius: 0 0 6px 6px;
        }
      }
    }
  }
  &__item-link {
    display: block;
    padding: 12px 0 12px 15px;
    @include fz(12);
    width: 100%;
    text-align: left;
    @include mq(lg) {
      padding: 34px 1rem 33px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include fz(14);
      height: 88px;
      text-align: center;
      cursor: pointer;
      width: 100%;
      transition: 0.2s;
      transition-property: background-color;
      &:hover {
        background: $c-color-primaryMoreLight;
      }
    }
    &.-accordion {
      background-color: #f1f2f3;
      @include mq(lg) {
        padding: 9px 1rem 10px;
        background-color: $c-color-base;
        height: auto;
        &:hover {
          background: $c-color-primaryMoreLight;
        }
      }
    }
  }
  &__item-link-arrow {
    display: none;
    color: $c-color-primary;
    vertical-align: middle;
    @include fz(16);
    @include mq(lg) {
      display: block;
    }
  }
  &__btn {
    position: absolute;
    margin-left: auto;
    display: block;
    width: 42px;
    height: 42px;
    flex-basis: 47px;
    flex-grow: 0;
    flex-shrink: 0;
    background: #0060af;
    top: 0;
    right: 0;
    &::before,
    &::after {
      position: absolute;
      content: '';
      display: block;
      background: #fff;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    &::before {
      width: 14px;
      height: 2px;
    }
    &::after {
      width: 2px;
      height: 14px;
    }
  }
  &__accordion {
    @include mq(lg) {
      box-shadow: 0px 24px 38px rgb(0 0 0 / 14%);
      position: absolute;
      width: 164px;
      height: 160px;
      & .l-nav__item {
        &:not(:first-child) {
          border-top: 1px solid $c-color-border;
        }
      }
    }
  }
}

// メニュー
.js-drawer[aria-expanded='false'] {
  transition-property: none;
  transform: translateX(100%);
  @include mq(lg) {
    transform: translateX(0);
  }
}

.js-drawer[aria-expanded='true'] {
  transition-property: transform;
  transition-duration: 0.25s;
  transition-timing-function: ease;
  @include mq(lg) {
    transform: translateX(0);
  }
}

// 背景
.js-drawer-bg[aria-expanded='false'] {
  opacity: 0;
  visibility: hidden;
}

.js-drawer-bg[aria-expanded='true'] {
  opacity: 1;
  visibility: visible;
  transition-duration: 0.25s;
}

.js-hamburger[aria-expanded] {
  & .js-ham-stripe {
    top: 0;
    width: 30px;
    height: 3px;
    background-color: #0060af;
    transition-duration: 0.25s;
    transition-timing-function: linear;
    &::before,
    &::after {
      position: relative;
      content: '';
      display: block;
      width: 30px;
      height: 3px;
      background-color: #0060af;
      transition-duration: 0.25s;
      transition-timing-function: linear;
    }
    &::before {
      top: 8px;
    }
    &::after {
      top: 13px;
    }
  }
}

// ハンバーガーメニュー
.js-hamburger[aria-expanded='true'] {
  & .js-ham-stripe {
    transform: translate3d(0, 8px, 0) rotate(45deg);
    &::before {
      transform: translate3d(0, -8px, 0) rotate(-45deg);
      opacity: 0;
    }
    &::after {
      transform: translate3d(0, -15px, 0) rotate(-90deg);
    }
  }
}

//
.js-accordion-trigger[aria-expanded='true'] {
  & .js-toggle-trigger-icon {
    &::after {
      width: 0;
    }
  }
}

.js-accordion-target[aria-expanded='false'] {
  visibility: hidden;
  transition-property: none;
  transform: translateY(-100%);
  max-height: 0;
  @include mq(lg) {
    opacity: 0;
    transition: 0.2s;
    transform: translateY(0);
  }
}

.js-accordion-target[aria-expanded='true'] {
  visibility: visible;
  transition-property: transform, height;
  transition-duration: 0.25s;
  transition-timing-function: ease;
  max-height: 999px;
  @include mq(lg) {
    opacity: 1;
    transition-property: opacity;
  }
}
