@use '../global'as *;

.tableset02_img {
  width: 100%;
  margin-bottom: 24px;

  &.-min {
    width: 162px;
    display: block;
    margin: 0 auto 24px;

    @include mq(md) {
      width: 290px;
    }
  }
}

.tableset03_img {
  width: 100%;
  margin-bottom: 24px;

  @include mq(md) {
    width: 360px;
    margin-left: 24px;

    &.-min {
      width: 278px;
    }
    &.-micro {
      width: 137px;
    }
  }
}
.tableset03_img-wrapper{
  @include mq(md) {
    display: block;
    width: 278px;
  }
}
