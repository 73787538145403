@use '../global' as *;
// ボタンデザイン

.btn {
  padding: 10px 0 9px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: $c-color-primary;
  color: $c-color-base;
  font-weight: bold;
  text-align: center;
  width: 100%;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  transition: $c-transition;
  transition-property: background-color;
  @include fz(13);

  @include mq(md) {
    padding: 15px 0;
    border-radius: 4px;
    @include fz(16);
    .material-icons {
      @include fz(18);
    }
  }

  & > span {
    @include fz(13);
    @include mq(lg) {
      @include fz(18);
    }
  }

  &:hover {
    background: #005398;
  }

  &.-secondary {
    background: $c-color-secondary;
    color: $c-color-text;

    &:hover {
      background: #fea827;
    }
  }

  &.-tertiary {
    background: $c-color-tertiary;
    @include fz(16);

    @include mq(md) {
      @include fz(18);
    }

    &:hover {
      background: darken($c-color-tertiary, $amount: 6);
    }
  }

  &.-base {
    background: $c-color-base;
    color: $c-color-primary;
    &:hover {
      background: $c-color-primaryMoreLight;
    }
  }

  &.-w200-162 {
    width: 200px;
    margin: auto;
    font-weight: normal;

    @include mq(md) {
      @include fz(13);
      width: 162px;
      border-radius: 6px;
      padding: 10px 0;
    }
  }

  &.-w200 {
    width: 200px;
    margin: auto;
    font-weight: normal;

    @include mq(md) {
      @include fz(13);
      border-radius: 6px;
      padding: 10px 0;
    }
  }

  &.-auto {
    display: inline-block;
    width: auto;
    padding: 10px 10px 9px;

    @include mq(md) {
      @include fz(13);
      border-radius: 6px;
      padding: 10px 20px;
    }
  }
}

.border-btn {
  display: block;
  padding: 6px 15px;
  border-radius: 4px;
  border: 1px solid #cccccc;
  color: #0060af;
  background: #fff;
  @include fz(12);
  font-weight: bold;
  text-align: center;
  transition-duration: 0.2s;
  transition-property: background-color;

  &:hover {
    background: $c-color-primaryMoreLight;
  }
}
