@use '../global' as *;

.staff-content {
  @include content-wrap();
  &__pc-top-wrap {
    @include mq(lg) {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
  }
  &__report-link {
    margin-top: 24px;
    @include mq(lg) {
      margin-top: 40px;
    }
  }
  &__pagenation-top {
    margin: 54px 0 24px;
    @include mq(lg) {
      margin-top: 40px;
    }
  }
  &__pagenation-bottom {
    margin: 24px 0 54px;
  }
}

.staff-list {
  display: grid;
  gap: 24px;
  @include mq(lg) {
    grid-template-columns: repeat(3, 1fr);
    gap: 24px 20px;
  }
  &__item {
    width: 100%;
    @include shadow-box;
    display: block;
    padding: 16px;
    @include mq(lg) {
      width: 300px;
      display: flex;
      align-items: stretch;
      display: flex;
      padding: 0;
      &:hover {
        transition: $c-transition;
        box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.2);
        background: $c-color-light;
      }
    }
  }
}

.staff-item {
  @include mq(lg) {
    width: 300px;
    cursor: pointer;
    box-shadow: 0 24px 38px rgba(0, 0, 0, 0.14);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    &:hover {
      .staff-item__img {
        transform: scale(1.1);
      }
    }
  }
  &__pc-img-wrap {
    display: block;
    @include mq(lg) {
      overflow: hidden;
      width: 252px;
    }
  }
  &__modal {
    width: 100%;
    text-align: left;
    @include mq(lg) {
      padding: 24px 24px 0;
    }
  }
  &__img {
    float: left;
    margin-right: 12px;
    width: 90px;
    @include mq(lg) {
      margin-right: 0;
      float: initial;
      width: 252px;
      transition: transform 0.7s ease, -webkit-transform 0.7s ease;
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
  &__contents {
    @include mq(lg) {
      padding: 0 24px 24px;
    }
  }
  &__title {
    display: block;
    @include clearfix;
    font-weight: bold;
    letter-spacing: 1.2px;
    @include fz(16);
    line-height: 1.75;
    @include mq(lg) {
      margin-top: 8px;
      @include fz(16);
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }
  }
  &__belong {
    display: block;
    margin-top: 8px;
    @include fz(11);
    color: #666666;
    @include mq(lg) {
      @include fz(12);
    }
  }
  &__staff-name {
    display: block;
    font-weight: bold;
    @include fz(12);
    @include mq(lg) {
      margin-top: 4px;
      @include fz(14);
    }
  }
  &__ico {
    vertical-align: middle;
    @include fz(16);
  }
  &__linkWrap {
    margin-top: 8px;
    @include fz(12);
  }
  &__link {
    color: $c-color-link;
    &:hover {
      text-decoration: underline;
    }
    @include mq(lg) {
      @include fz(14);
    }
  }
}

.staff-introduction {
  &__character {
    display: flex;
  }
  &__img {
    width: 120px;
     flex-basis: 120px;
    flex-shrink: 0;
    flex-grow: 0;
  }
  &__block {
    margin-left: 16px;
  }
  &__appeal {
    font-weight: 600;
  }
  &__belong {
    margin-top: 12px;
    color: #999;
    @include fz(10);
  }
  &__staff-name {
    font-weight: bold;
  }
  &__contents {
    margin-top: 16px;
  }
  &__img-wrap {
    margin: 16px 0 24px;
    display: grid;
    gap: 24px;
    grid-template-columns: repeat(2, 1fr);
  }
  &__icon {
    vertical-align: middle;
    color: $c-color-secondaryDark;
  }
  &__item {
    margin-top: 8px;
    display: flex;
    align-items: center;
    @include fz(11);
    @include mq(lg) {
      @include fz(14);
    }
  }
}

.staff-title {
  padding: 14px 8px 13px;
  background: $c-color-bg;
  border-bottom: 1px solid $c-color-border;
  font-weight: bold;
}
