@use '../global' as *;

.c-link {
  color: $c-color-link;
  @include fz(14);
  &__ico {
    vertical-align: middle;
    @include fz(16);
  }
}

.border-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 15px;
  background: #fff;
  color: $c-color-primary;
  border-radius: 6px;
  border: 2px solid $c-color-border;
  width: 100%;
  text-align: left;
  font-weight: 600;
  transition: $c-transition;
  transition-property: background-color;
  &:hover {
    background: $c-color-light;
  }
  &::after {
    @include arrow(10, 2, $c-color-primary, bottom);
    margin-left: 8px;
  }
  &:not(:first-child) {
    margin-top: 24px;
  }
  &.-active {
    background: $c-color-light;
    color: $c-color-primary;
  }
  &.-main-border {
    position: relative;
    border: 1px solid $c-color-primary;
    &::before {
      position: absolute;
      right: 20px;
      top: 20px;
      @include arrow(7, 2, $c-color-primary, right);
    }
    &.-active {
      background: $c-color-light;
      color: $c-color-primary;
      &::before {
        @include arrow(7, 2, $c-color-primary, right);
      }
    }
    &:hover {
      transition: $c-transition;
      background: $c-color-light;
    }
  }
  &__text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 80%;
  }
}

.c-anchor-btn-box {
  &__inner {
    @include mq(lg) {
      display: flex;
      gap: 16px;
    }
  }
  &__btn {
    &:not(:first-child) {
      margin-top: 24px;
      @include mq(lg) {
        margin-top: 0;
      }
    }
  }
}
