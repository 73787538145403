@use '../global' as *;
//  パンクず
.breadcrumb-list {
  padding: 8px 0;
  background: $c-color-base;
  &__inner {
    @include content-wrap;
  }
  &__list {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    @include fz(10);
    @include mq(lg) {
      @include fz(12);
    }
  }
  &__item {
    display: inline-block;
    &.-ellipsis {
      width: 50%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    & .material-icons {
      vertical-align: middle;
      @include fz(12);
      @include mq(lg) {
        @include fz(16);
        vertical-align: bottom;
      }
    }
  }
  &__item-link {
    color: $c-color-primary;
  }
  &__home {
    vertical-align: baseline;
    margin-right: 3px;
  }
  &__arrow {
    padding: 0 6px;
    @include fz(12);
    color: $c-color-border;
  }
}

.c-page-parts {
  border-bottom: 1px solid $c-color-border;
}

.c-page-title {
  @include fz(16);
  font-weight: bold;
  padding-bottom: 10px;
  @include content-wrap;
  font-weight: bold;
  @include mq(lg) {
    @include fz(24);
  }
}
