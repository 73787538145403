@use '../global' as *;
/*===========================
/exam/
サンプルページ
=========================== */
code {
  display: block;
  padding: 1rem;
  background: rgba(#eee, 0.5);
}
.exam {
  // PC SPの横幅マージンはこちらのincludeを利用してください。
  @include content-wrap();
  // メディアクエリmin-width940px
  @include mq(lg) {
    // pcの記述はこちらに。
  }
}

.ex-fz {
  @include fz(18);
}
