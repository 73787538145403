@use '../global' as *;

//タブ
.u-pc-hide {
  @include mq(md) {
    display: none;
  }
}

.u-sp-hide {
  display: none;

  @include mq(md) {
    display: block;
  }
}

.u-center {
  text-align: center;
}

.u-bg-blue01 {
  background-color: #f2f5ff;
}

.u-bg-blue02 {
  background-color: #0060af;

  .heading {
    &::after {
      background-color: #fff;
    }
  }
}

.u-bg-darkblue {
  color: #fff;
  background-color: #003662;
}

.u-mb0 {
  margin-bottom: 0 !important;
}
