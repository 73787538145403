@use '../global' as *;
/*===========================
/shop/
=========================== */
.shop-page {
  &__anchor {
    @include content-wrap();
    margin-top: 24px;
    margin-bottom: 24px;
    @include mq(lg) {
      margin-top: 40px;
      margin-bottom: 40px;
    }
  }
  &__shop-list {
    margin-bottom: 54px;
    @include mq(lg) {
      @include content-wrap();
      margin-bottom: 96px;
    }
  }
}

// 店舗詳細ページ
.shop-detail-page {
  &__inner {
    @include content-wrap();
    margin-top: 40px;
    &:not(:first-child) {
      margin-top: 90px;
    }
  }
  &__list {
    margin-top: 40px;
    @include mq(lg) {
      margin-top: 96px;
    }
  }
  &__shop {
    margin: 54px 0;
    @include mq(lg) {
      margin: 24px auto 0;
      width: 620px;
    }
  }
  &__bnr {
    @include content-wrap();
    margin-top: 54px;
    margin-bottom: 54px;
  }
  &__section {
    @include content-wrap();
    &:not(:first-child) {
      margin-top: 54px;
    }
    &.-no-margin {
      overflow-x: hidden;
      margin: 0;
      @include mq(lg) {
        @include content-wrap();
      }
    }
  }
  &__pc-access {
    @include mq(lg) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  }
}

// 店舗詳細ヘッダー
.shop-header {
  padding: 24px 15px 24px;
  background: #f0f1f3;
  @include mq(lg) {
    padding-bottom: 40px;
  }
  &__inner {
    display: grid;
    grid-template-columns: 120px 1fr;
    grid-template-rows: max-content 1fr;
    column-gap: 16px;

    @include mq(lg) {
      @include content-wrap();
      grid-template-rows: max-content 1fr 1fr;
      grid-template-columns: 240px 1fr 1fr;
      min-height: 298px;
      &.-nodetail {
        grid-template-rows: max-content 1fr;
        min-height: 180px;
      }
    }
  }
  &__imgWrap {
    grid-row: 1;
    grid-column: 1;
    @include mq(lg) {
      grid-row: 1;
      grid-column: 1;
    }
  }
  &__nameWrap {
    grid-row: 2;
    grid-column: 2 / span 3;
    @include mq(lg) {
      grid-row: 1;
      grid-column: 2 / span 3;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
  &__businessHour {
    grid-row: 1;
    grid-column: 2 / span 3;
    @include mq(lg) {
      grid-column: 2 / span 2;
    }
  }
  &__name {
    font-weight: bold;
    @include fz(16);
    @include mq(lg) {
      @include fz(28);
    }
  }
  &__img {
    max-width: 120px;
    @include mq(lg) {
      max-width: 240px;
      width: 240px;
    }
  }
  &__data {
    grid-row: 3;
    grid-column: 1 / span 4;
    @include mq(lg) {
      grid-row: 3;
      grid-column: 1 / span 4;
    }
  }
  &__phone-link {
    color: $c-color-link;
    @include fz(12);
    & .material-icons-outlined {
      margin-right: 4px;
      @include fz(12);
      vertical-align: middle;
    }
    @include mq(lg) {
      display: block;
      margin-top: 8px;
      @include fz(14);
    }
  }
}

// 店舗詳細アイコン一覧
.menu-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 60px 60px;
  gap: 8px;
  color: #666;
  text-align: center;
  @include fz(10);
  @include mq(lg) {
    display: grid;
    grid-template-columns: repeat(8, 100px);
    grid-template-rows: repeat(1, 100px);
    gap: 20px;
    color: #666;
    text-align: center;
    @include fz(14);
  }
  &__item {
    padding: 8px;
    border-radius: 5px;
    background: $c-color-base;
    @include mq(lg) {
      padding: 8px 0;
      display: flex;
      flex-direction: column;
    }
    > svg {
      width: 30px;
      @include mq(lg) {
        width: 40px;
      }
    }
    path {
      fill: $c-color-label;
    }
    &.is-active {
      background-color: #0060af;
      color: #fff;
      path {
        fill: #fff;
      }
    }
  }
  &__img {
    width: 30px;
    margin: auto;
    @include mq(lg) {
      width: 40px;
      margin: auto;
    }
  }
}

// タブ
.shop-tab-wrap {
  border-bottom: 1px solid $c-color-border;
  @include mq(lg) {
    background-color: #f0f1f3;
  }
  &__inner {
    background-color: #f0f1f3;
    @include mq(lg) {
      @include content-wrap();
    }
  }
}

.shop-tab {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background-color: #f0f1f3;
  width: 94%;
  margin: auto;
  @include mq(lg) {
    display: inline-flex;
    justify-content: flex-start;
  }
  li {
    width: 100%;
    @include mq(lg) {
      width: auto;
    }
    &:not(:first-child) {
      margin-left: 4px;
    }
  }
  &__item {
    padding: 8px 0;
    border-radius: 8px 8px 0 0;
    border: 1px solid $c-color-border;
    border-bottom: none;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    @include fz(14);
    line-height: 1.25;
    font-weight: bold;
    color: $c-color-base;
    background-color: $c-color-primary;
    @include mq(lg) {
      padding: 14px 12px;
      cursor: pointer;
      display: inline-flex;
      width: auto;
    }
    &.-active {
      margin-bottom: -2px;
      color: $c-color-primary;
      background-color: $c-color-base;
    }
  }
  &__content {
    display: none;
    &.-show {
      display: block;
    }
  }
}

// 店舗スライダー
.shop-slide-area {
  padding-bottom: 10px;
  margin-top: 40px;
  position: relative;
  overflow: hidden;
  .swiper {
    overflow: visible; // 1.はみ出させるように visible で上書き
  }
}

.shop-slide {
  &__item {
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 4px rgba(192, 189, 189, 0.25);
  }
  &__text {
    padding: 10px 15px 30px;
    @include fz(14);
    min-height: 145px;
    @include mq(lg) {
      min-height: 90px;
    }
  }
  &__img {
    width: 100%;
  }
  .slick-track {
    display: flex;
  }
}

.shop-slide-arrow {
  position: absolute;
  top: 50%;
  z-index: 1;
  width: 40px;
  height: 80px;
  background-color: #f2f5ff;
  -webkit-box-shadow: 0 4px 4px rgba(192, 189, 189, 0.25);
  box-shadow: 0 4px 4px rgba(192, 189, 189, 0.25);

  &::before {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-top: 2px solid #0060af;
    border-right: 2px solid #0060af;
    content: '';
    vertical-align: middle;
    position: absolute;
    top: 44%;
    left: 38%;
  }
}

.shop-slide-arrow--prev {
  left: 25px;

  &::before {
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }
}

@media (min-width: 940px) {
  .shop-slide-arrow--prev {
    left: 7px;
  }
}

.shop-slide-arrow--next {
  right: 25px;

  &::before {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

@media (min-width: 940px) {
  .shop-slide-arrow--next {
    right: 7px;
  }
}

.shop-access {
  @include mq(lg) {
    width: 460px;
    &:nth-child(odd) {
      margin-left: 0;
    }
    &:nth-child(n + 3) {
      margin-top: 24px;
    }
  }
  &:not(:first-of-type) {
    margin-top: 40px;
    @include mq(lg) {
      margin-top: 0;
      margin-left: 20px;
    }
  }

  &__img {
    display: block;
    margin: auto;
    width: 100%;
    @include mq(lg) {
      display: block;
      margin: auto;
      width: 100%;
    }
  }
  &__situation {
    margin-top: 10px;
    font-weight: bold;
    @include fz(16);
  }
  &__text {
    margin-top: 10px;
    @include fz(12);
  }
}

.shop-info-table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  @include fz(12);
  font-family: 'Hiragino sans';
  @include mq(lg) {
    height: 180px;
  }
  &__address-td {
    width: 70%;
  }
  &__address-text {
    @include fz(12);
    @include mq(lg) {
      @include fz(14);
    }
  }
  &__business-hour {
    display: none;
    @include mq(lg) {
      display: table-row;
    }
  }
  &__phone-link {
    color: $c-color-link;
    @include fz(12);
    & .material-icons {
      margin-right: 4px;
      @include fz(12);
      vertical-align: middle;
    }
    @include mq(lg) {
      display: block;
      margin-top: 8px;
      @include fz(14);
    }
  }
  &__btn {
    margin-top: 8px;
    @include mq(lg) {
      > .btn {
        width: 200px;
        padding: 10px;
        > span {
          @include fz(14);
        }
      }
    }
  }
  tr {
    @include mq(lg) {
      background: #f0f1f3;
    }
  }
  th,
  td {
    @include mq(lg) {
      border: 1px solid #ccc;
      font-weight: normal;
      padding: 12px;
      text-align: left;
    }
  }

  th {
    display: none;
    @include mq(lg) {
      display: table-cell;
      background: #f0f1f3;
      vertical-align: baseline;
    }
  }

  td {
    @include mq(lg) {
      padding: 10px 16px;
      background-color: #fff;
    }
  }

  img {
    vertical-align: middle;
  }
  & .icon {
    display: flex;
    align-items: center;
    & .material-icons-outlined {
      margin-right: 4px;
      color: #08be8e;
    }
  }
}

.shop-table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  @include fz(12);

  th,
  td {
    border: 1px solid #ccc;
  }

  th {
    padding: 10px 16px;
    width: 130px;
    background-color: #f1f2f3;
    color: #666;
    vertical-align: top;
    text-align: left;
    font-weight: normal;
  }

  td {
    padding: 10px 16px;
    background-color: #fff;
  }

  img {
    vertical-align: middle;
  }
}

//整備詳細
.result-item01 {
  padding-bottom: 24px;
  &__compare {
    display: flex;
    flex-direction: column;
    gap: 24px;
    & .before {
      display: block;
      position: relative;
      &::before {
        content: 'before';
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 80px;
        height: 51px;
        background: $c-color-base;
        color: $c-color-primary;
        font-weight: bold;
        text-align: center;
      }
    }
    & .after {
      display: block;
      position: relative;
      &::before {
        content: 'after';
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        right: 0;
        width: 80px;
        height: 51px;
        background: $c-color-secondary;
        color: $c-color-primary;
        font-weight: bold;
        text-align: center;
      }
    }
    @include mq(lg) {
      @include content-wrap();
      flex-direction: row;
      justify-content: center;
    }
  }
  &__img {
    width: 100%;
    @include mq(lg) {
      margin: 40px 0;
    }
  }
  &__update {
    margin-top: 16px;
    @include fz(12);
    color: #999;
    font-weight: bold;
  }
  &__title-text {
    margin-top: 8px;
    @include fz(16);
    font-weight: 600;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
  }
  &__label {
    margin-top: 16px;
  }
  &__item-name {
    margin-top: 16px;
    @include fz(12);
    color: $c-color-text-gray;
    @include mq(lg) {
      display: flex;
    }
  }
  &__item-text {
    &:not(:first-of-type) {
      margin-top: 4px;
      @include mq(lg) {
        padding-left: 8px;
        border-left: 1px solid $c-color-border;
        margin: 0 0 0 8px;
      }
    }
  }
  &__price {
    margin-top: 16px;
  }
  &__text {
    margin-top: 24px;
  }
  &__detail-box {
    margin-top: 24px;
  }
  &__pc-wrap {
    @include mq(lg) {
      margin: auto;
      width: 620px;
    }
  }
}

.result-item02 {
  &:not(:first-child) {
    margin-top: 24px;
  }
  &__img {
    width: 100%;
  }
  &__update {
    margin-top: 16px;
    @include fz(12);
    color: #999;
    font-weight: bold;
  }
  &__title-text {
    margin-top: 8px;
    @include fz(16);
    font-weight: 600;
  }
  &__label {
    margin-top: 16px;
  }
  &__item-name {
    margin-top: 16px;
    @include fz(12);
    color: $c-color-text-gray;
  }
  &__price {
    margin-top: 16px;
  }
  &__text {
    margin-top: 24px;
    + .result-item02__text {
      margin-top: 24px;
    }
  }
}

//テキスト文
.text-content {
  p {
    margin-top: 1rem;
    @include fz(14);
    @include mq(lg) {
      @include fz(16);
    }
  }
  img {
    display: block;
    margin: 40px auto 0;
  }
}
//車両情報
.table-wrap {
  margin-top: 24px;
}

.table01 {
  width: 100%;
  @include fz(12);
  @include mq(lg) {
    margin: auto;
    width: 620px;
  }
  &__item {
    display: flex;
    align-items: center;
    background: $c-color-bg;
    border: 1px solid $c-color-border;
    &:not(:first-of-type) {
      border-top: 1px solid $c-color-border;
    }
  }
  &__title {
    padding: 10px 16px;
    width: 45%;
    color: $c-color-text-gray;
    background: $c-color-bg;
    &.-price-text {
      padding: 13px 16px;
      @include fz(13);
      font-weight: bold;
    }
  }
  &__content {
    border-left: 1px solid $c-color-border;
    padding: 10px 16px;
    width: 55%;
    background: $c-color-base;
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    &.-right {
      text-align: right;
    }
    &.-amount {
      color: $c-color-primaryDark;
      @include fz(17);
      font-weight: bold;
    }
  }
  &__shop-link {
    color: $c-color-primary;
    text-decoration: underline;
  }
}

.table02 {
  width: 100%;
  border: 1px solid #cccccc;
  border-radius: 4px;
  background-color: #fff;
  &__item {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    text-align: center;
    @include fz(10);
    &:first-of-type {
      border-bottom: 1px solid #ccc;
    }
    &:nth-of-type(odd) {
      background: $c-color-bg;
    }
    &:last-child {
      border-bottom: none;
    }
  }
  &__title {
    padding: 6px 0;
    background: $c-color-bg none repeat scroll 0 0;
    &.-sp-title {
      border-right: 1px solid $c-color-border;
      &:last-of-type {
        border-right: none;
      }
    }
  }
  &__detail {
    padding: 6px 0;
  }
  &__link {
    color: $c-color-primary;
    text-decoration: underline;
  }
  .row1 {
    border-right: 1px solid $c-color-border;
    width: 45%;
    text-align: left;
    padding-left: 8px;
  }
  .row2 {
    border-right: 1px solid $c-color-border;
    width: 20%;
    text-align: center;
  }
  .row5 {
    width: 35%;
    text-align: right;
    padding-right: 8px;
  }
}

.category-label {
  display: inline-block;
  padding: 4px 12px 3px;
  border: 1px solid $c-color-primary;
  border-radius: 16px;
  background: $c-color-base;
  color: $c-color-primary;
  @include fz(12);
  @include mq(lg) {
    padding: 2px 10px;
  }
}

.price-parts {
  &__label {
    display: inline-block;
    padding: 3px 10px;
    color: $c-color-base;
    background: $c-color-caution;
    @include fz(12);
  }
  &__price {
    margin-left: 12px;
    color: $c-color-caution;
    font-weight: bold;
    vertical-align: middle;
    @include fz(18);
    @include mq(lg) {
      @include fz(20);
    }
  }
}

.shop-data {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  @include fz(12);
  font-family: 'Hiragino sans';
  @include mq(lg) {
    @include fz(14);
  }
  tr {
    background: #f0f1f3;
    @include mq(lg) {
      border: 1px solid $c-color-border;
    }
  }
  th,
  td {
    border: 1px solid #ccc;
    font-weight: normal;
    padding: 12px;
    text-align: left;
  }

  th {
    background: #f0f1f3;
    @include mq(lg) {
      display: block;
      padding: 13px 16px;
      border: none;
    }
  }

  td {
    padding: 10px 16px;
    background-color: #fff;
    @include mq(lg) {
      padding: 13px 16px;
    }
  }
  td > div {
    @include mq(lg) {
      display: inline-block;
      margin-right: 15px;
    }
  }

  img {
    vertical-align: middle;
  }
  & .icon {
    display: flex;
    align-items: center;
    @include mq(lg) {
      display: inline-flex;
      vertical-align: middle;
      &:not(:first-of-type) {
        margin-left: 16px;
      }
    }
    & .material-icons-outlined {
      margin-right: 4px;
      color: #08be8e;
    }
  }
}

// お知らせ
.information {
  &__title {
    padding: 15px 15px 0;
    border-top: 1px solid $c-color-border;
    @include mq(lg) {
      padding-top: 24px;
    }
  }
  &__date {
    @include fz(11);
    color: $c-color-text-gray;
  }
  &__body {
    padding: 0 15px 15px;
    margin-top: 16px;
    @include mq(lg) {
      padding-top: 10px;
      margin-top: 0;
    }
    &:last-of-type {
      border-bottom: 1px solid $c-color-border;
    }
  }
  &__link {
    &:hover {
      color: $c-color-primary;
    }
    @include mq(lg) {
      @include fz(16);
    }
  }
}

//お知らせラベル
.info-label {
  display: inline-block;
  padding: 3px 24px;
  @include fz(11);
  border: 1px solid $c-color-primary;
  color: $c-color-primary;
  background: $c-color-base;
  @include mq(lg) {
    padding: 1px 12px;
    @include fz(12);
  }
}

.shop-gmap {
  margin-top: 24px;
  width: 100%;
  height: 328px;
  border: none;
  @include mq(lg) {
    height: 390px;
  }
}
