@use '../global' as *;

/*===========================
/whychoos/
=========================== */
.whychoose-page {
  &__select-section {
    margin: 54px 15px;
    @include mq(lg) {
      @include content-wrap();
      margin-top: 40px;
    }
  }
  &__heading {
    span {
      @include fz(20);
      @include mq(lg) {
        @include fz(28);
      }
    }
  }
}

.blue-bgbox {
  background-color: $c-color-primaryMoreLight;
  padding: 20px;
  line-height: 1.75;

  @include mq(md) {
    display: flex;
    padding: 40px 40px 18px;
    margin: 40px 0;
  }
}

.blue-bgbox__img {
  margin-bottom: 24px;

  @include mq(md) {
    img {
      width: 180px;
      margin-right: 25px;
    }
  }

  img {
    display: block;
    margin: 0 auto;
  }
}

.blue-bgbox__heading {
  font-weight: bold;
  margin-bottom: 2px;

  @include mq(md) {
    @include fz(20);
  }
}

.blue-bgbox__txt {
  @include fz(12);
  margin-bottom: 15px;

  @include mq(md) {
    @include fz(16);
    margin-bottom: 18px;
  }
}

.blue-bgbox__appeal {
  display: inline;
  font-weight: bold;
  @include fz(14);
  border-bottom: 4px solid #ffc631;

  @include mq(md) {
    @include fz(20);
  }
}

.blue-bgbox__meta {
  @include mq(md) {
    flex: 1;
  }
}

.reason-item {
  margin-bottom: 48px;
  counter-increment: reason;

  &::before {
    content: counter(reason);
    color: #ffc631;
    @include fz(40);
    text-align: center;
    display: block;
  }

  header {
    padding: 0 40px;
    margin-bottom: 40px;
  }

  @include mq(md) {
    &:nth-child(1) {
      .reason-item__logo {
        img {
          width: 67px;
        }
      }
    }

    &:nth-child(2) {
      .reason-item__logo {
        img {
          width: 211px;
        }
      }
    }

    &:nth-child(3) {
      .reason-item__logo {
        img {
          width: 103px;
        }
      }
    }
  }
  &__txt-link {
    text-decoration: underline;
    color: $c-color-base;
  }
}

.reason-item__logo {
  text-align: center;
  padding: 20px 0 16px;

  @include mq(md) {
    text-align: left;
  }
}

.reason-item__body {
  picture {
    img {
      width: 100%;
    }
  }

  @include mq(md) {
    display: flex;

    > div {
      flex: 1;
    }

    picture {
      img {
        width: 380px;
        margin-right: 20px;
      }
    }
  }
}
