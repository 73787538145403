@use '../global' as *;
/*===========================
トップ
=========================== */
.contentbox {
  padding-bottom: 40px;

  &.--wash {
    margin-bottom: 60px;

    @include mq(md) {
      .cleaningbox {
        min-height: 220px;
      }

      margin-bottom: 0;
      padding-bottom: 55px;
    }
  }

  @include mq(md) {
    padding-bottom: 100px;
  }
}

.color-coating-unit {
  margin-top: 40px;

  @include mq(md) {
    display: flex;
    justify-content: space-between;
  }
}

.color-coating-box {
  margin-bottom: 40px;

  @include mq(md) {
    width: 32%;
  }

  &__crystal {
    .color-coating-box__header {
      background-color: #4fa6d3;
    }

    .color-coating-box__body__box-txt {
      background-color: rgba(79, 166, 211, 0.5);
    }
  }

  &__diamond {
    .color-coating-box__header {
      background-color: #baa657;
    }

    .color-coating-box__body__box-txt {
      background-color: rgba(186, 166, 87, 0.5);
    }
  }

  &__pure {
    .color-coating-box__header {
      background-color: #da6a2a;
    }

    .color-coating-box__body__box-txt {
      background-color: rgba(218, 106, 42, 0.5);
    }
  }
}

.color-coating-box__header {
  text-align: center;
  padding-top: 9px;
  padding-bottom: 2px;

  @include mq(md) {
    padding-top: 16px;
    padding-bottom: 12px;
  }
}

.color-coating-box__header__txt {
  @include fz(24);
  font-weight: bold;

  span {
    @include fz(14);
  }
}

.color-coating-box__header__badge {
  @include fz(12);
  color: #fff;
  display: inline-block;
  padding: 5px 21.5px;
  background-color: #222;
  margin-bottom: 5px;

  @include mq(md) {
    @include fz(14);
  }
}

.color-coating-box__body {
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding-top: 16px;
  padding-bottom: 24px;

  @include mq(md) {
    padding-bottom: 20px;
  }
}

.color-coating-box__body__box-txt {
  @include fz(14);
  margin-left: 16px;
  margin-bottom: 5px;
  font-weight: bold;
  padding: 12px 0 12px 12px;

  @include mq(md) {
    margin-bottom: 15px;
    min-height: 96px;
    display: flex;
    align-items: center;
    letter-spacing: 0.1em;
    @include fz(16);
    padding: 12px 16px;
  }
}

.color-coating-box__body__imgwrapper {
  img {
    width: 208px;
    display: block;
    margin-left: auto;

    @include mq(md) {
      width: 260px;
    }
  }
}

.color-coating-box__body__name {
  text-align: center;
  @include fz(28);

  font-weight: bold;

  @include mq(md) {
    @include fz(40);
    text-align: left;
    margin-top: 18px;
    margin-bottom: 20px;
    padding-left: 20px;
    line-height: 1;

    br {
      display: block;
    }
  }

  br {
    display: none;
  }
}

.color-coating-box__body__txt {
  margin: 8px 20px;
  @include fz(14);

  @include mq(md) {
    min-height: 100px;
    letter-spacing: 0.15em;
  }
}

.section-text01 {
  @include mq(md) {
    text-align: center;
    margin-top: -12px;
  }
}

.section-text02 {
  text-align: center;
  color: $c-color-base;
}

.color-coating-box__body__price {
  text-align: center;
  color: #e60012;
  @include fz(30);
  font-weight: bold;

  @include mq(md) {
    @include fz(40);

    span {
      @include fz(14);
    }
  }

  span {
    @include fz(12);
    font-weight: normal;
  }
}

.card01-unit {
  margin-top: 40px;
  margin-bottom: 40px;

  @include mq(md) {
    margin-top: 20px;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
  }
}

.card01 {
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 20px 20px 80px;
  margin: 24px 0;
  position: relative;

  @include mq(md) {
    width: 32%;
    margin-top: 20px;
    padding-bottom: 75px;
  }
}

.card01__header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  img {
    border-radius: 50%;
    margin-right: 16px;
  }

  div {
    font-weight: bold;
    @include fz(18);
  }

  @include mq(md) {
    margin-bottom: 10px;
    display: block;

    img {
      width: 160px;
      height: 160px;
      display: block;
      margin: 0 auto 10px;
    }

    div {
      text-align: center;
      @include fz(20);

      br {
        display: none;
      }
    }
  }
}

.card01__txt {
  @include mq(md) {
    letter-spacing: 0.1em;
  }
}

.card01__btn {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  @include fz(13);
  border-radius: 4px;
  width: 200px;
  padding: 9.5px 0;
  margin: 0 auto;
  text-align: center;
  border: 1px solid #ccc;
  color: #0060af;

  @include mq(md) {
    width: calc(100% - 40px);
  }
}

.catlist {
  @include fz(9);
  display: flex;
  gap: 0 10px;
  color: #3b7de9;
  flex-wrap: wrap;

  @include mq(md) {
    @include fz(11);
  }
}

.staff-list {
  margin: 40px 0;
}

.account {
  text-align: center;
  @include fz(20);
  margin-top: 10px;
  margin-bottom: 16px;
}

.instagram_photo {
  margin: 40px 0;
  display: grid;
  gap: 8px;
  justify-content: center;
  grid-template-columns: repeat(auto-fit, 100px);

  @include mq(md) {
    margin-top: 20px;
    gap: 35px;
    grid-template-columns: repeat(auto-fit, 160px);

    img {
      width: 100%;
    }

    li {
      &:last-child {
        display: none;
      }
    }
  }
}

//タブ2つ目
.cleaningbox {
  border: 1px solid #ccc;
  padding: 19px 0px 19px 24px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  align-items: flex-end;

  @include mq(md) {
    position: relative;
    width: 49%;
    align-items: center;
  }
}

.cleaningbox__heading {
  @include fz(20);
  font-weight: bold;
  margin-bottom: 5px;

  @include mq(md) {
    margin-bottom: 20px;
    @include fz(24);
  }
}

.cleaningbox__txt {
  @include fz(11);
  color: #222222;

  @include mq(md) {
    @include fz(16);
  }
}

.cleaningbox__yen {
  color: #e60012;
  @include fz(28);
  font-weight: bold;

  @include mq(md) {
    @include fz(40);

    span {
      margin-left: 2px;
      @include fz(14);
    }
  }

  span {
    @include fz(10);
    font-weight: normal;
  }
}

.cleaningbox__meta {
  padding-right: 50px;

  @include mq(md) {
    padding-right: 5px;

    & + img {
      position: absolute;
      right: 0;
      bottom: 20px;
      width: 200px;
      max-width: 25%;

      @include mq(lg) {
        max-width: 50%;
      }
    }
  }
}

.cleaningbox-unit--room {
  .cleaningbox__meta {
    @include mq(md) {
      & + img {
        width: 160px;
      }
    }
  }
}

.cleaningbox-unit--coating {
  .cleaningbox__meta {
    @include mq(md) {
      & + img {
        width: 140px;
      }
    }
  }
}

.cleaningbox-unit {
  margin-bottom: 20px;

  @include mq(md) {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}

.js-fade-in {
  opacity: 0;
}
.js-scroll-in {
  animation-name: animation1;
  animation-duration: 2s;
  animation-fill-mode: forwards;
  &:nth-child(2) {
    animation-name: animation1;
    animation-delay: 0.3s;
  }
  &:nth-child(3) {
    animation-name: animation1;
    animation-delay: 0.6s;
  }
}

@keyframes animation1 {
  0% {
    opacity: 0;
    transform: translateY(0);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
