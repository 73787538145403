@use '../global' as *;
//  見出しデザイン
.heading {
  margin: 0 auto 24px;
  display: table;
  @include fz(24);
  font-weight: bold;
  text-align: center;
  @include mq(lg) {
    @include fz(28);
  }
  &::after {
    margin-top: 4px;
    display: block;
    content: '';
    width: 100%;
    height: 8px;
    background: $c-color-primary;
  }
  &.-left {
    text-align-last: left;
  }
  &.-base {
    color: $c-color-base;
  }
}

// 灰色背景デザイン
.c-bg-title {
  border-bottom: 1px solid $c-color-border;
  background: $c-color-bg;
  width: 100%;
  padding: 11px 15px;
  @include fz(14);
  @include mq(lg) {
    background: $c-color-bg;
    width: 100%;
    padding: 11px 15px;
    @include fz(18);
  }
  &:not(:first-of-type) {
    margin-top: 40px;
    @include mq(lg) {
      margin-top: 40px;
    }
  }
}
