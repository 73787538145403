@use '../global' as *;
// 後ほどトップページのscssへ移動
.top-qa {
  @include content-wrap();
  margin-top: 54px;
  margin-bottom: 54px;
  @include mq(lg) {
    margin-top: 96px;
    margin-bottom: 96px;
  }
  &__link {
    margin: 40px auto 54px;
    width: 200px;
  }
}

.top-shop-list {
  display: flex;
  align-items: center;
  position: relative;
  background: url('/_files/_keeper/images/img_shop_bg_sp.jpg') no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vw;
  @include mq(lg) {
    background: url('/_files/_keeper/images/img_shop_bg_pc.jpg') no-repeat;
    background-size: cover;
    height: 463px;
  }
  &__box {
    padding: 40px 20px;
    width: calc(100% - 30px);
    position: static;
    margin-right: auto;
    margin-left: auto;
    background: $c-color-base;
    @include mq(lg) {
      width: 940px;
    }
  }
  &__text {
    text-align: center;
  }
  &__link {
    margin: 40px auto 0;
    width: 200px;
  }
}

.top-column {
  @include content-wrap();
  margin-top: 54px;
  margin-bottom: 54px;
  @include mq(lg) {
    margin-top: 96px;
    margin-bottom: 96px;
  }
}
