@use '../global' as *;
// lineバナー
.line-bnr {
  // margin-left: 15px;
  // margin-right: 15px;
  &__link {
    transition: 0.2s;
    transition-property: opacity;
    &:hover {
      opacity: 0.8;
    }
  }
  &__img {
    display: block;
    margin: auto;
    @include mq(lg) {
      @include content-wrap();
      width: 940px;
      height: 244px;
    }
  }
}
