@use '../global'as *;


.iconlist {
  display: grid;
  gap: 8px;
  margin-bottom: 24px;

  grid-template-columns: repeat(auto-fit, 75px);

  @include mq(md) {
    margin-bottom: 40px;

    &.-min {
      grid-template-columns: repeat(auto-fit, 74px);

      li {
        width: 74px;

        .iconlist_txt {
          @include fz(10);
        }
      }
    }
  }

  li {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    height: 65px;
    border-radius: 4px;
    background-color: #0060AF;
    color: #fff;

    &.-gray {
      background-color: #D4D8DD;
    }
  }

  justify-content: center;

  @include mq(md) {
    gap: 4px;
    grid-template-columns: repeat(auto-fit, 90px);

    li {
      justify-content: space-between;
      padding-top: 8px;
      width: 90px;
      height: 85px;
    }
  }
}

.iconlist_icon {
  margin-bottom: 2px;

  @include mq(md) {
    margin-bottom: 5px;
  }
}

.iconlist_txt {
  @include fz(10);
  line-height: 1.2;

  @include mq(md) {
    display: flex;
    @include fz(12);
    justify-content: center;
    align-items: center;
    height: 40px;
  }

  &.-min {
    white-space: nowrap;
    transform: scale(0.8);
  }
}
